import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import DashboardCard from "../../components/DashboardCard/DashboardCard";

export const Dashboard = () => {
	const state = useSiteState();
	const [data, setData] = React.useState({});
	const [monthlyData, setMonthlyData] = React.useState([]);

	const [loading, setLoading] = React.useState(true);

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getDashboardDetails = () => {
		ApiHelper.get(APIURLConstants.DASHBOARD_DETAILS)
			.then((res) => {
				setLoading(false);
				console.log("======>", res);
				setData(res.data.result);
				setMonthlyData(res.data.result.monthly_order);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		getDashboardDetails();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						Dashboard
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							width: "80%",
							mx: "Auto",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "50%",
								mx: "Auto",
							}}
						>
							<DashboardCard data={data.total_user} title={"Total Users"} />
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "50%",
								mx: "Auto",
							}}
						>
							<DashboardCard data={data.total_order} title={"Total Orders"} />
						</Box>
					</Box>

					<Typography
						component="h1"
						variant="h5"
						alignContent={"center"}
						// textAlign={"center"}
						fontWeight={600}
						padding={2}
					>
						Monthly Total Orders
					</Typography>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							width: "80%",
							mx: "Auto",
						}}
					>
						{monthlyData.length > 0 &&
							monthlyData.map((key, index) => {
								return (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											width: "50%",
											// padding: 2,
											mx: "Auto",
										}}
									>
										<DashboardCard
											data={key.count}
											title={key.month_name}
											key={index}
										/>
									</Box>
								);
							})}
					</Box>
				</Box>
			)}
		</Box>
	);
};
