import { createState, useState } from "@hookstate/core";
// import Helper from "../utillity/helper";
const initialState = {
	products: [],
};

const cartState = createState(initialState);

export const setCartState = (props) => {
	if (props) {
		cartState.set({
			products: props,
		});
	}
};

export const useCartState = () => {
	return useState(cartState);
};
