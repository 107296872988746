import { createState, useState } from "@hookstate/core";
// import Helper from "../utillity/helper";
const initialState = {
	products: [],
};

const planState = createState(initialState);

export const setPlanState = (props) => {
	if (props) {
		planState.set({
			products: props,
		});
	}
};

export const usePlanState = () => {
	return useState(planState);
};
