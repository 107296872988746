import React from "react";
import { Box } from "@mui/material";

import Signupgraphic from "../../assets/signupgraphic.png";
// import Logingraphic from "../../assets/logingraphic.png";

import { Typography } from "@mui/material";
import Register from "./Register";

export const SignUp = () => {
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					// flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
					padding: { xs: 1, md: 4 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: "center",
						background: "#FFFFFF",
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						width: { xs: "100%", md: "80%" },
						// height: "calc(100vh - 100px)",

						mx: "auto",
					}}
				>
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							// height: "calc(100vh - 70px)",

							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							px: { xs: 1, md: 4 },
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<Register />
					</Box>
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							// height: "calc(100vh - 70px)",
							marginTop: 19,
							display: { xs: "none", md: "flex" },
							flexDirection: "column",
							px: { xs: 1, md: 2 },
						}}
					>
						<Box
							component="img"
							src={Signupgraphic}
							// sx={{ marginTop: "-60px" }}
							sx={{
								objectFit: "contain",
								width: "30em",
							}}
						/>
						{/* <Typography
							fontSize={20}
							fontWeight={600}
							lineHeight={"30px"}
							sx={{ py: 2 }}
						>
							Lorem Ipsum is simply dummy text of the printing and typesetting
						</Typography>
						<Typography fontSize={14} variant="subtitle1" lineHeight={"21px"}>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							Lorem Ipsum is simply dummy text of the printing and typesetting
							Lorem Ipsum is simply dummy text of the printing and typesetting
						</Typography> */}
					</Box>
				</Box>
			</Box>
		</>
	);
};
