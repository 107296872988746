import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Blur from "../../assets/blur.svg";
import nBlur from "../../assets/nblur.svg";

export default function CartCard(props) {
	const { price_symbol, annualPrice, asin } = props.cartItem;
	const removeCard = (product_asin) => {
		props.removeProduct(product_asin);
	};

	return (
		<Box sx={{ m: { xs: 2, md: 2 }, zIndex: 999 }}>
			<Card
				sx={{
					display: "flex",
					// width: "376px",
					width: { xs: "100%", md: "340px" },
					height: "115px",
					borderRadius: "12px",
					border: "1px solid #0072B1",
					// boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.25)",
					boxShadow: " 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)",
					margin: { xs: "0 auto", md: "0" },
				}}
			>
				<CardMedia
					component="img"
					sx={{
						width: 81,
						filter: "blur(8px)",
						width: "125px",
						height: "125px",
						objectFit: "contain",
					}}
					image={props.cartItem.image}
					alt="product image"
				/>
				<CardContent
					sx={{ width: "232px", display: "flex", flexDirection: "row-reverse" }}
				>
					<Box>
						{props.showDelete && (
							<IconButton
								aria-label="delete"
								onClick={() => {
									removeCard(asin);
								}}
							>
								<DeleteIcon />
							</IconButton>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							p: { xs: 1, md: 2 },
						}}
					>
						<Typography fontSize={15} fontWeight={600} color={"secondary.main"}>
							{/* ${Math.floor(Math.random() * 100000) + 1} */}
							{price_symbol + " "} {annualPrice}
						</Typography>
						<Typography fontSize={14} fontWeight={500}>
							Product income
						</Typography>
						<Typography fontSize={14} fontWeight={500}>
							$ 25
						</Typography>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
