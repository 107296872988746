export default class Helper {
	static GetNameInitials(name) {
		if (name) {
			//to remove (NBA)
			name = name.includes("(") ? name.split("(")[0] : name;
			let nameArr = name.split(",");

			if (nameArr)
				name = (nameArr[1] ? nameArr[1].trim() : "") + ", " + nameArr[0];

			var iChars = "";
			name.replace(/[a-z]{2,}/gi, function (c) {
				iChars += c.charAt(0);
			});
			return iChars.toUpperCase();
		} else return "";
	}

	/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
	static removeStorage(key) {
		try {
			localStorage.removeItem(key);
			localStorage.removeItem(key + "_expiresIn");
		} catch (e) {
			//console.log('removeStorage: Error removing key [' + key + '] from localStorage: ' + JSON.stringify(e));
			return false;
		}
		return true;
	}
	/*  getStorage: retrieves a key from localStorage previously set with setStorage().
        params:
            key <string> : localStorage key
        returns:
            <string> : value of localStorage key
            null : in case of expired key or failure
     */
	static getStorage(key) {
		var now = Date.now(); //epoch time, lets deal only with integer
		// set expiration for storage
		var expiresIn = localStorage.getItem(key + "_expiresIn");
		if (expiresIn === undefined || expiresIn === null) {
			expiresIn = 0;
		}

		if (expiresIn < now) {
			// Expired
			this.removeStorage(key);
			return null;
		} else {
			try {
				var value = localStorage.getItem(key);
				return JSON.parse(value);
			} catch (e) {
				//console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
				return null;
			}
		}
	}
	/*  setStorage: writes a key into localStorage setting a expire time
        params:
            key <string>     : localStorage key
            value <string>   : localStorage value
            expires <number> : number of seconds from now to expire the key
        returns:
            <boolean> : telling if operation succeeded
     */
	static setStorage(key, value, expires) {
		if (expires === undefined || expires === null) {
			expires = 24 * 60 * 60; // default: seconds for 1 day
		} else {
			expires = Math.abs(expires); //make sure it's positive
		}

		var now = Date.now(); //millisecs since epoch time, lets deal only with integer
		var schedule = now + expires * 1000;
		try {
			localStorage.setItem(key, JSON.stringify(value));
			localStorage.setItem(key + "_expiresIn", schedule);
		} catch (e) {
			//console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
			return false;
		}
		return true;
	}

	static clearLocalStorage() {
		if (localStorage) {
			let app_version = localStorage.app_version_reps;
			localStorage.clear();
			if (app_version) {
				localStorage.setItem("app_version_reps", app_version);
			}
		}
	}

	static validEmail(email) {
		var isCheckEmail = new RegExp(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
		);
		if (isCheckEmail.test(email)) {
			return true;
		}
		return false;
	}

	static validName(name) {
		var isCheck = new RegExp(/^[a-zA-Z ]*$/);
		if (isCheck.test(name)) {
			return true;
		}
		return false;
	}
}
