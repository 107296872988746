import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
export const FeedList = () => {
	const state = useSiteState();
	const [data, setData] = React.useState([]);
	const navigate = useNavigate();

	const [loading, setLoading] = React.useState(true);

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getFeedDetails = () => {
		ApiHelper.get(APIURLConstants.ALL_FEEDS)
			.then((res) => {
				setLoading(false);
				setData(res.data.result.feed);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleSubmit = () => {
		setLoading(true);
		const data = {
			user_id: state.user.id.get(),
		};

		ApiHelper.post(APIURLConstants.UPDATE_USER, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Something is wrong",
					});
					// getSubscriptionDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "User details are updated",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};
	const removeFeed = (id) => {
		const data = {
			id: id,
		};
		ApiHelper.post(APIURLConstants.DELETE_FEED, data)
			.then((res) => {
				setLoading(false);
				getFeedDetails();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const editFeed = (data) => {
		navigate(RouteConstants.ADMIN_FEED, {
			state: { feed: data },
		});
	};

	useEffect(() => {
		getFeedDetails();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						All Feeds
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",

							mx: 2,
						}}
					>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 400 }}
								size="small"
								// aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell align="left">Title</TableCell>
										<TableCell align="left">Description</TableCell>
										{/* <TableCell align="right">Order</TableCell> */}
										<TableCell align="left">Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.length > 0 ? (
										data.map((row) => {
											return (
												<TableRow
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<TableCell scope="row">{row.title}</TableCell>
													<TableCell align="left">
														{row.description.length > 50
															? `${row.description.substring(0, 50)}...`
															: row.description}
													</TableCell>

													<TableCell align="left">
														<IconButton
															aria-label="delete"
															onClick={() => {
																removeFeed(row.id);
															}}
														>
															<DeleteIcon />
														</IconButton>
														<IconButton
															aria-label="delete"
															onClick={() => {
																editFeed(row);
															}}
														>
															<EditIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
												fontWeight: 400,
												fontSize: 13,
												mx: "auto",
												paddingLeft: 8,
											}}
										>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell component="th" scope="row">
												<Typography
													color="initial"
													alignContent={"center"}
													marginLeft={4}
												>
													No Feeds Found
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
