import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import StripeContainer from "../Stripe/StripeContainer";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";

export const PriceCard = (props) => {
	let navigate = useNavigate();

	const [redirectPayment, setRedirectPayment] = React.useState(false);
	const handlePayment = (price) => {
		if (props.type === "unlimited") {
			navigate(RouteConstants.PAYMENT_GATEWAY, {
				state: { amount: price, type: props.type, cart: [] },
			});
			return;
		}
		setRedirectPayment(false);
		if (props.totalReport === 0) {
			navigate(RouteConstants.LANDING);
		} else {
			navigate(RouteConstants.PAYMENT_GATEWAY, {
				state: {
					amount: price * props.totalReport,
					cart: props.cart,
					type: props.type,
				},
			});
		}
	};
	return (
		<Box sx={{ m: { xs: 0, md: 4 } }}>
			{redirectPayment ? (
				<Box sx={{ width: "100%", height: "100%" }}>
					<StripeContainer price={props.reportPrice} />
				</Box>
			) : (
				<Card
					sx={{
						width: { xs: 290, md: 400 },
						height: { xs: 360, md: 390 },
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
					}}
				>
					<CardContent>
						<Box
							sx={{
								height: { xs: 100, md: 110 },
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								mx: "auto",
								alignItems: "center",
								backgroundColor: `${props.colorParams}`,
							}}
						>
							<Typography
								fontWeight={600}
								fontSize={{ xs: 26, md: 32 }}
								color="primary"
								alignContent={"center"}
							>
								{props.reportTitle + " "}
							</Typography>
							<Typography
								fontWeight={600}
								fontSize={{ xs: 28, md: 36 }}
								color="primary"
								alignContent={"center"}
								sx={{ display: "flex", flexDirection: "row" }}
							>
								&nbsp;
								{props.type !== "single" && (
									<Box
										sx={{
											textDecoration: "line-through",
											color: "red",
											marginRight: 2,
										}}
									>
										<Typography
											fontWeight={600}
											fontSize={{ xs: 28, md: 36 }}
											color={"white"}
										>
											$50{" "}
										</Typography>
									</Box>
								)}
								{"  $" +
									(props.totalReport === 0
										? props.reportPrice
										: props.reportPrice * props.totalReport)}
							</Typography>
						</Box>
						<Box
							sx={{
								height: { xs: "150px", md: "190px" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									// justifyContent: "center",
									// alignContent: "center",
									// justifyItems: "center",

									// alignItems: "center",
									mx: "auto",
									marginLeft: { xs: 3, md: 7 },
									// py: 1.5,
									paddingTop: 2,
									// fontSize:19
								}}
							>
								<Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										<Typography
											fontWeight={600}
											// align="center"
											// textAlign={"center"}
											// marginLeft={3}
										>
											Includes :
										</Typography>
									</Box>
									<Box sx={{ display: "flex" }}>
										<Typography
											fontSize={{ xs: "1rem", md: 19 }}
											fontWeight={500}
										>
											{props.type === "single"
												? "1 Instant Report"
												: "50 Instant Reports"}
										</Typography>
									</Box>
									<Box sx={{ display: "flex" }}>
										<Typography
											fontSize={{ xs: "1rem", md: 19 }}
											fontWeight={500}
										>
											Sales Revenue
										</Typography>
									</Box>
									<Box sx={{ display: "flex" }}>
										<Typography
											fontSize={{ xs: "1rem", md: 19 }}
											fontWeight={500}
										>
											{" "}
											Selling Price
										</Typography>
									</Box>
									{/* <Box sx={{ display: "flex" }}>
										<Typography fontWeight={500}>
											Product Description
										</Typography>
									</Box> */}
									<Box sx={{ display: "flex" }}>
										<Typography
											fontSize={{ xs: "1rem", md: 19 }}
											fontWeight={500}
										>
											{props.type === "single"
												? "Ratings"
												: "$1 for the first month, $50 thereafter"}
										</Typography>
									</Box>
									{/* <Box sx={{ display: "flex" }}>
										<Typography fontWeight={500} git>
											Search (SEO) Keywords
										</Typography>
									</Box> */}
									<Box sx={{ display: "flex" }}>
										<Typography
											fontSize={{ xs: "1rem", md: 19 }}
											fontWeight={500}
										>
											{props.type === "single" ? " " : "Cancel Anytime"}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</CardContent>
					<CardActions
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center",
							marginTop: { xs: "1em", md: 0 },
						}}
					>
						{props.showButton ? (
							<Button
								variant="contained"
								size="small"
								color="primary"
								sx={{
									marginTop: "1em",
									margin: "1em",
									backgroundColor: `${props.colorParams}`,
									color: "primary.main",
									borderRadius: "4px",
									height: "42px",
									boxShadow: `0px 8px 30px ${props.colorParams}`,
									padding: "4px 28px",
								}}
								onClick={() => {
									handlePayment(props.reportPrice, props.totalReport);
								}}
							>
								{props.buttonTilte}
							</Button>
						) : null}
					</CardActions>
				</Card>
			)}
		</Box>
	);
};
