import React from "react";
import CardContainer from "./CardContainer";
import { Box } from "@mui/material";

export const AddCard = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				height: "100%",
				p: { xs: 0, md: 1 },
			}}
		>
			<CardContainer />
		</Box>
	);
};
