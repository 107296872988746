import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import HomeBgImage from "../../assets/home-bgImage.svg";
import HomeAnimatedImage from "../../assets/home-animated-image.svg";
import TopSellIcon from "../../assets/top-selling-icon.svg";
import PersonImage from "../../assets/person.png";
import ArrowImage from "../../assets/click-here.png";
import TrendingIcon from "../../assets/trending-icon.svg";
import BudgetIcon from "../../assets/budget-icon.svg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import ProductCard from "../../components/Product/ProductCard";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
import CornerPatternImage from "../../assets/corner-pattern.png";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import { setCartState } from "../../hookState/CartState";
import { setSiteState, useSiteState } from "../../hookState/SiteState";
import { usePlanState } from "../../hookState/PlanState";
import { Downgraded } from "@hookstate/core";
import { premiumCount } from "../../constants/Constant";
import { setProfileState } from "../../hookState/ProfileState";
import { Typography } from "@mui/material";
import {
	setSocialLoginState,
	useSocialLoginState,
} from "../../hookState/SocialLoginState";
const useStyles = makeStyles({
	bgImage: {
		backgroundImage: `url(${HomeBgImage})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		color: "#ffffff",
		padding: "1em",
		textAlign: "center",
	},
	btnIcon: {
		width: "21px",
		height: "21px",
		display: "block",
		marginRight: "15px",
	},
	personImage: {
		position: "absolute",
		bottom: "0",
		right: "0",
		width: "259px",
	},
	arrowImage: {
		position: "absolute",
		width: "150px",
		top: "175px",
		right: "-100px",
	},
	topSelling: {
		"&:hover": {
			backgroundColor: "#FFAC1C",
			boxShadow: "0px 5px 25px #ffac1c",
		},
	},
	btnSecondary: {
		"&:hover": {
			backgroundColor: "#0081FF",
			boxShadow: "0px 3px 20px #0081FF",
		},
	},
});

const Landing = () => {
	const state = useSiteState();
	const planState = usePlanState();
	let navigate = useNavigate();
	const [cartCount, setCartCount] = useState(0);
	const [token, setToken] = useState("");
	const classes = useStyles();
	const [perPage] = useState(3);
	const [currentPage] = useState(1);
	const [products, setProducts] = useState([]);
	let [cart, setCart] = useState([]);
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const [cartAdded, setCartAdded] = useState([]);
	const [loading, setLoading] = useState(false);
	const [categoryId] = useState("all");

	const loginOptionName = Helper.getStorage("loginOption");
	if (loginOptionName) {
		setSocialLoginState(loginOptionName);
	}
	const loginOption = useSocialLoginState();
	const search = window.location.search;

	const params = new URLSearchParams(search);
	// setToken(params.get("code"));
	const token_id = params.get("code");

	const handleRedirect = (path) => {
		debugger;
		console.log(path);
		if (path === "topSelling") {
			navigate(RouteConstants.TOPSELLING);
		} else if (path === "trending") {
			navigate(RouteConstants.product);
		} else {
			navigate(RouteConstants.BUDGET);
		}

		navigate(RouteConstants.product);
	};

	useEffect(() => {
		// console.log("token Id -----", token_id);
		// if (token_id) {
		// 	postLoggedIn(token_id);
		// 	setToken(token_id);
		// 	return;
		// }
		console.log("token", token_id);

		postLoggedIn();
		setToken(token_id);
	}, [token_id]);
	// if (token) {
	// 	return postLoggedIn(token);
	// }

	const postLoggedIn = async () => {
		console.log("postLoggedIn");
		// const socialsign = (await loginOption.get()) && loginOption.get().name;
		if (!token_id) {
			return false;
		}
		const data = {
			token: token_id,
			provider: loginOptionName,
		};
		console.log("postLoggedIn aage ka", data);
		// console.log("postLoggedIn aage ka", loginOption.get());

		ApiHelper.post(APIURLConstants.GOOGLE_CALLBACK, data)
			.then((res) => {
				if (res.data.success) {
					setLoading(false);
					Helper.setStorage("userInfo", res.data.result);
					setSiteState(res.data.result);
					const cart = Helper.getStorage("cart");
					let cartAsin = [];
					if (cart) {
						let cartCopy = [...cart];
						cartAsin = cartCopy.map((key) => key.asin);
						const data = {
							cartAsin: cartAsin,
							user_id: res.data.result.user.id,
						};
						ApiHelper.post(APIURLConstants.add_products_cart, data)
							.then((response) => {
								if (res.data.success) {
									Helper.removeStorage("cart");
								}
							})
							.catch((error) => {
								console.log(error);
							});
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getData = (id) => {
		setLoading(true);
		const data = {
			per_page: perPage,
			current_page: currentPage,
			category_id: categoryId,
			user_id: id,
		};
		ApiHelper.post(APIURLConstants.get_products, data)
			.then((res) => {
				setLoading(false);

				setProducts(res.data.result.products);
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const getProducts = () => {
		if (state.user.get()) {
			getData(state.user.id.get());
		} else {
			const cart = Helper.getStorage("cart");
			let cartAsin = [];
			if (cart) {
				let cartCopy = [...cart];
				cartAsin = cartCopy.map((key) => key.asin);
			}
			setLoading(true);
			const data = {
				per_page: perPage,
				current_page: currentPage,
				category_id: categoryId,
				user_id: 0,
				cartAsin: cartAsin,
			};
			ApiHelper.post(APIURLConstants.get_products, data)
				.then((res) => {
					setLoading(false);
					setProducts(res.data.result.products);
				})
				.catch((error) => {
					setLoading(false);
				});
		}
	};
	const getCart = (id) => {
		let data = { user_id: id };
		ApiHelper.post(APIURLConstants.GET_CART, data)
			.then((res) => {
				// setCartCount(res.data.result.products.length);
				setCartState(res.data.result.products);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const addCartItem = (productInfo) => {
		if (
			state.plan.get() !== null &&
			state.plan !== "" &&
			state.plan !== undefined
		) {
			if (cartCount >= premiumCount) {
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Please contact to contact@ecominsider.net",
				});
				return;
			}
			ApiHelper.post(APIURLConstants.add_products_myaccount, productInfo)
				.then((res) => {
					console.log(res);
					if (res.data) {
						if (res.data.success) {
							setSnackBarProps({
								openSnack: true,
								variant: "success",
								message: "Product Added in MyAccount",
							});
							getProducts();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			ApiHelper.post(APIURLConstants.ADD_TO_CART, productInfo)
				.then((res) => {
					console.log(res);
					if (res.data) {
						if (res.data.success) {
							setSnackBarProps({
								openSnack: true,
								variant: "success",
								message: "Product Added in Card",
							});
							getProducts();
							getCart(productInfo.user_id);
						} else {
							setSnackBarProps({
								openSnack: true,
								variant: "success",
								message: "Product Already in Cart",
							});
							return;
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	const addItem = (item) => {
		const cart = Helper.getStorage("cart");
		if (cart) {
			let cartCopy = [...cart];
			let { id } = item;
			let existingItem = cartCopy.find((cartItem) => cartItem.id == id);

			if (existingItem) {
				setSnackBarProps({
					openSnack: true,
					variant: "success",
					message: "Product Already in Cart",
				});
				return;
			} else {
				cartCopy.push(item);
			}
			setCart(cartCopy);
			setCartState(cartCopy);
			Helper.setStorage("cart", cartCopy);
			setSnackBarProps({
				openSnack: true,
				variant: "success",
				message: "Product Added in Card",
			});
		} else {
			let cart = [];
			let cartCopy = [...cart];
			let { id } = item;
			let existingItem = cartCopy.find((cartItem) => cartItem.id == id);

			if (existingItem) {
				setSnackBarProps({
					openSnack: true,
					variant: "success",
					message: "Product Already in Cart",
				});
				return;
			} else {
				cartCopy.push(item);
			}
			setCart(cartCopy);
			setCartState(cartCopy);
			Helper.setStorage("cart", cartCopy);
			setSnackBarProps({
				openSnack: true,
				variant: "success",
				message: "Product Added in Card",
			});
		}
		getProducts();
	};
	useEffect(() => {
		getProducts();
	}, [perPage, currentPage]);

	useEffect(() => {
		setCartCount(planState.products.attach(Downgraded).get().length);
	}, [planState && planState.products]);

	// const checkProductExist = (p_asin) => {
	// 	if (p_asin && cartAdded && cartAdded.length > 0) {
	// 		console.log("in", cartAdded);
	// 		const cartStatus =
	// 			cartAdded && cartAdded.find((key) => key.asin === p_asin);
	// 		console.log(cartStatus && cartStatus.cart_status_id);
	// 		if (cartStatus !== undefined) {
	// 			return cartStatus.cart_status_id;
	// 		}
	// 	}
	// 	return 0;
	// };

	const checkProductExist = (p_asin) => {
		if (state.user.get()) {
			if (p_asin && cartAdded && cartAdded.length > 0) {
				const cartStatus =
					cartAdded && cartAdded.find((key) => key.asin === p_asin);
				if (cartStatus !== undefined) {
					return cartStatus.cart_status_id;
				}
			}
			return 0;
		} else {
			const cart = Helper.getStorage("cart");
			// console.log("Cart------", cart);
			if (cart) {
				let cartCopy = [...cart];
				if (cartCopy && cartCopy.length > 0) {
					let exist = cartCopy.some((item) => item.asin === p_asin);
					if (exist && exist !== undefined) {
						return 1;
					} else {
						return 0;
					}
				} else {
					return 0;
				}
			}
		}
	};

	const getProductCartDetails = () => {
		const productInfo = {
			user_id: state.user.id.get(),
		};
		ApiHelper.post(APIURLConstants.GET_PRODUCT_CART, productInfo)
			.then((res) => {
				if (res.data) {
					if (res.data.success) {
						setCartAdded(res.data.result.cart);
					} else {
						setCartAdded([]);
						// setEmailErr("The email has already been taken.");
						return;
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (state.user.get()) {
			getProductCartDetails();
		}
	}, []);
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				<Box
					className={classes.bgImage}
					sx={{
						width: { xs: "100%", md: "60%" },
						display: { xs: "flex", md: "none" },
						flexDirection: "column",
						height: "408px",
						position: "relative",
					}}
				>
					<Typography fontWeight={600} fontSize={21} color={"#000"}>
						Save time and money on product research!
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
						<Button
							variant="contained"
							className={classes.topSelling}
							sx={{
								m: 1,
								backgroundColor: "#FFAC1C",
								borderRadius: "8px",
								boxShadow: "0px 8px 30px #ffac1c",
								textTransform: "none",
								color: "#ffffff",
								padding: "7px 10px",
								marginTop: 7,
								width: "50%",
							}}
							onClick={() => navigate(RouteConstants.TOPSELLING)}
						>
							<Typography fontSize={13}>Find Products</Typography>

							<SearchIcon className={classes.btnIcon} sx={{ mx: 1 }} />
						</Button>
					</Box>
					<Box
						component="img"
						sx={{
							position: "absolute",
							bottom: "0",
							right: "3",
							width: "259px",
						}}
						alt="logo."
						src={PersonImage}
					/>
				</Box>

				<Box
					className={classes.bgImage}
					sx={{
						width: { xs: "100%", md: "60%" },
						display: { xs: "none", md: "flex" },
						flexDirection: "row",
						height: "408px",
						position: "relative",
					}}
				>
					<Box sx={{ display: { xs: "none", md: "flex" } }}>
						<img
							src={`${HomeAnimatedImage}`}
							style={{
								width: { xs: "0px", md: "380px" },
								marginLeft: "auto",
								marginRight: "auto",
							}}
							alt="home"
						/>
					</Box>

					<img
						src={`${PersonImage}`}
						className={classes.personImage}
						alt="person"
					/>

					<img
						src={`${ArrowImage}`}
						className={classes.arrowImage}
						alt="person"
					/>
				</Box>

				<Box
					sx={{
						width: { xs: "100%", md: "40%" },
						marginBottom: { xs: "2px", md: 0 },
					}}
				>
					<Box>
						<Box
							sx={{
								width: "100%",
								display: { xs: "none", md: "flex" },
								flexDirection: "column",
								justifyContent: "center",
								marginTop: 9,
								py: 0,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<Typography
									fontWeight={600}
									fontSize={26}
									color={"#000"}
									align="right"
									alignContent={"right"}
									py={3}
								>
									Save time and money on product research!
								</Typography>
							</Box>
							<Button
								variant="contained"
								className={classes.topSelling}
								sx={{
									m: 1,
									backgroundColor: "#FFAC1C",
									borderRadius: "8px",
									boxShadow: "0px 8px 30px #ffac1c",
									textTransform: "none",
									color: "#ffffff",
									padding: "15px 45px",
									width: "230px",
									justifyContent: "center",
									alignSelf: "center",
								}}
								onClick={() => navigate(RouteConstants.TOPSELLING)}
							>
								{/* <img
									src={`${TopSellIcon}`}
									alt="icon"
									className={classes.btnIcon}
								/> */}
								Find Products
								<SearchIcon className={classes.btnIcon} sx={{ mx: 1 }} />
							</Button>
						</Box>

						{/* <Box
							sx={{
								width: { xs: "90%", md: "70%" },
								display: "flex",
								flexDirection: "row",
								justifyContent: "between",
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							<Button
								variant="contained"
								className={classes.btnSecondary}
								sx={{
									m: 1,
									backgroundColor: "#0081FF",
									borderRadius: "8px",
									boxShadow: "0px 6px 25px rgba(0, 129, 255, 0.55)",
									textTransform: "none",
									color: "#ffffff",
									padding: "10px 30px",
									marginRight: "auto",
									width: "154px",
								}}
								onClick={() => navigate(RouteConstants.product)}
							>
								<img
									src={`${TrendingIcon}`}
									alt="icon"
									className={classes.btnIcon}
								/>
								Trending
							</Button>

							<Button
								variant="contained"
								className={classes.btnSecondary}
								sx={{
									m: 1,
									backgroundColor: "#0081FF",
									borderRadius: "8px",
									boxShadow: "0px 6px 25px rgba(0, 129, 255, 0.55)",
									textTransform: "none",
									color: "#ffffff",
									padding: "10px 30px",
									marginLeft: "auto",
									width: "154px",
								}}
								onClick={() => navigate(RouteConstants.BUDGET)}
							>
								<img
									src={`${BudgetIcon}`}
									alt="icon"
									className={classes.btnIcon}
								/>
								Budget
							</Button>
						</Box> */}
					</Box>
				</Box>
				<Box
					sx={{
						// height: "100%",
						width: "100%",
						// backgroundImage:
						// 	"linear-gradient(to righ, #0081FF,#00DBB3,#107EBC, #6D00C3)",
						backgroundImage:
							"linear-gradient(to right,#0081FF, #00DBB3, #0081FF)",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xd: "column", md: "row" },
							// height: "100%",
							width: "100%",
							p: { xs: 0, md: 3 },
							justifyContent: "center",
							position: "relative",
							flexWrap: { xs: "wrap", md: "nowrap" },
						}}
					>
						<img
							src={`${CornerPatternImage}`}
							style={{ position: "absolute", top: "0", left: "0" }}
							alt="top-pattern"
						/>
						<img
							src={`${CornerPatternImage}`}
							style={{
								position: "absolute",
								bottom: "0",
								right: "0",
								transform: "rotate(180deg)",
							}}
							alt="bottom-pattern"
						/>
						{/* {[0, 1, 2].map((key, index) => {
							return <ProductCard key={index} data={key} />;
						})} */}

						{products.map((product, index) => {
							return (
								<ProductCard
									key={index}
									data={product}
									myAccount={false}
									addItem={addItem}
									addCartItem={addCartItem}
									checkProductExist={checkProductExist}
								/>
							);
						})}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Landing;
