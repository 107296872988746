import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import Headroom from "react-headroom";
import Header from "./components/Header/Header";
import { InnerRouter } from "./router/InnerRouter";
import { Footer } from "./components/Footer/Footer";
import { logout, setSiteState } from "./hookState/SiteState";
import Helper from "./utillity/helper";
import { useProfileState } from "./hookState/ProfileState";
import { Navbar } from "./components/Navbar/Navbar";
import Cookies from "./components/Cookies/Cookies";

function App() {
	const profileState = useProfileState();

	let theme = createTheme({
		palette: {
			primary: {
				main: "#fff",
				// main: "#FF0000",
			},
			secondary: {
				// main:'#fff'
				main: "#4165ae",
			},
			tertiary: {
				main: "#FFAC1C",
			},
			common: {
				white: "#FFF",
			},
		},
		components: {
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						minWidth: 30,
						color: "#fff",
					},
				},
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						padding: 0,
					},
				},
			},
			MuiListItemText: {
				styleOverrides: {
					primary: {
						color: "#000000",

						fontfamily: "Roboto, Helvetica, Arial, sans-serif",
						fontWeight: 400,
						fontSize: 14,
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						height: 70,
					},
				},
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						color: "primary.main",
					},
				},
			},
			MuiAvatar: {
				styleOverrides: {
					img: {
						objectFit: "contain",
					},
				},
			},

			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: "16px", // this needs to check using spacing
					},
				},
			},
			// MuiOutlinedInput: {
			// 	styleOverrides: {
			// 		root: {
			// 			borderRadius: "16px", // this needs to check using spacing
			// 		},
			// 	},
			// },
			MuiFormControl: {
				styleOverrides: {
					root: {
						margin: 2, // this nees to check using spacing
					},
				},
			},
		},
	});

	if (Helper.getStorage("userInfo")) {
		const userInfo = Helper.getStorage("userInfo");
		// console.log("userInfo in app", userInfo);
		setSiteState(userInfo);
	}
	useEffect(() => {
		setTimeout(() => {
			logout();
		}, 60 * 60 * 1000);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Box>
				<Router>
					<CssBaseline />
					<Headroom>
						<Header />
					</Headroom>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Box sx={{ zIndex: 99999 }}>
							<Cookies />
						</Box>

						<Box
							sx={{
								height: {
									xs: "100%",
									md: "calc(100vh - 110px)",
									overflow: "scroll",
								},
								width: "100%",
							}}
						>
							<InnerRouter />
						</Box>
						<Box>
							{profileState.visible.get() && (
								<Box
									sx={{
										display: {
											xs: "none",
											md: "block",
										},
										height: "calc(100vh - 110px)",
										overflow: "scroll",
									}}
								>
									<Navbar />
								</Box>
							)}
						</Box>
					</Box>
					<Box sx={{ height: "100%", width: "100%" }}>
						<Footer />
					</Box>
				</Router>
			</Box>
		</ThemeProvider>
	);
}

export default App;
