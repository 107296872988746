/* eslint-disable */

import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snackbars(props) {
	const vertical = "top";
	const horizontal = "center";

	return (
		<Stack spacing={2} sx={{ width: "100%" }}>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={props.openSnack}
				autoHideDuration={3000}
				onClose={props.onClose}
			>
				<Alert
					onClose={props.onClose}
					severity={props.variant}
					sx={{ width: "100%" }}
				>
					{props.message}
				</Alert>
			</Snackbar>
		</Stack>
	);
}
