import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import { useNavigate } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useSiteState } from "../../hookState/SiteState";
// import { useState } from "@hookstate/core";
import ProductCard from "../../components/Product/ProductCard";
import RouteConstants from "../../constants/RouteConstant";
import { setPlanState } from "../../hookState/PlanState";
import Skeleton from "@mui/material/Skeleton";
import ProductLoadingCard from "../../components/Product/ProductLoadingCard";

export const MyAccount = () => {
	const state = useSiteState();
	let navigate = useNavigate();
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(50);

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getProducts = () => {
		setLoading(true);
		const data = { user_id: state.user.id.get() };
		console.log(data);
		ApiHelper.post(APIURLConstants.GET_MY_PRODUCTS, data)
			.then((res) => {
				setLoading(false);
				setProducts(res.data.result.products);
				setPlanState(res.data.result.products);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		if (state.user.get()) {
			getProducts();
		} else {
			navigate(RouteConstants.LOGIN);
		}
	}, []);

	// useEffect(() => {
	// 	alert(products.length);
	// }, [products]);

	const removeProduct = (product_asin) => {
		if (state.user.get() !== "") {
			let data = { user_id: state.user.id.get(), asin: product_asin };
			ApiHelper.post(APIURLConstants.REMOVE_MYPRODUCT_ITEM, data)
				.then((res) => {
					console.log(res);
					if (res.data.success) {
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "Product removed",
						});
					}
					getProducts();
				})
				.catch((error) => {
					console.log(error);
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something wrong",
					});
				});
		}
	};

	// useEffect(() => {
	// 	console.log(search);
	// 	console.log(search.length);

	// 	getProducts();

	// 	//getProducts();
	// }, [search]);
	return (
		<>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						p: 1,
						justifyContent: "center",
						flexDirection: { xs: "column", md: "column" },
						width: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							paddingTop: 4,
							paddingLeft: { xs: 4, md: 3 },
							borderRadius: "3px",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<BusinessCenterIcon
								fontSize="large"
								sx={{ color: "tertiary.main", mx: 1 }}
							/>
							<Typography fontSize={24} fontWeight={500}>
								My Account
							</Typography>
						</Box>

						<Box sx={{ mr: "2em" }}>
							{products.length > 0 && (
								<Typography fontSize={16} fontWeight={400}>
									{products.length + " of " + total}
								</Typography>
							)}
						</Box>
					</Box>
					{loading ? (
						<Typography fontSize={15} color="initial" padding={4}>
							Loading....
						</Typography>
					) : (
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								flexDirection: { xs: "column", md: "row" },
								// justifyContent: "center",
								alignItems: "center",
								mx: { xs: 0, md: 10 },
							}}
						>
							{products.length > 0 ? (
								products.map((product, index) => {
									return (
										<ProductCard
											key={index}
											data={product}
											myAccount={true}
											removeProduct={removeProduct}
										/>
									);
								})
							) : (
								<Typography fontSize={15} color="initial" padding={4}>
									No Data Found{" "}
								</Typography>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};
