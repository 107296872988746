import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

// import AppleIcon from "@mui/icons-material/Apple";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GoogleIcon from "@mui/icons-material/Google";
// import InstagramIcon from "@mui/icons-material/Instagram";
import { TextField, Button, Typography, Grid } from "@mui/material";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import { setSiteState } from "../../hookState/SiteState";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
import {
	setSocialLoginState,
	changeSocialState,
} from "../../hookState/SocialLoginState";
import validator from "validator";
import { setCartState } from "../../hookState/CartState";
import Snackbars from "../../components/Snackbar/Snackbar";

// import GIcon from "../../assets/google.svg";
export const ResetDetails = () => {
	const useStyles = makeStyles((theme) => ({
		margin: {
			margin: theme.spacing(1),
		},
		socialButton: {
			margin: theme.spacing(1),
		},

		textField: {
			width: 400,
			[theme.breakpoints.down("md")]: {
				width: 320,
			},
			display: "flex",
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&:hover fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&.Mui-focused fieldset": {
					borderColor: theme.palette.secondary.main,
				},
			},
			"&:hover .MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
			"& .Mui-focused.MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
		},
		outlinedInput: {
			"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
			"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: "200px",
					height: "70px",
				},
			},
		},
	}));
	const classes = useStyles();

	let navigate = useNavigate();
	const [emailErr, setEmailErr] = React.useState("");
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		if (data.get("email") === "") {
			setEmailErr("Email required");
			return;
		} else {
			const email = data.get("email").trim();
			const flag = email !== "" ? validateEmail(email) : true;
			if (!flag) {
				const emlErr = flag ? "" : "Please enter valid Email";
				setEmailErr(emlErr);
				return;
			}
		}

		let obj = {};
		ApiHelper.post(APIURLConstants.SEND_OTP, data)
			.then((res) => {
				if (res.data) {
					if (res.data.success) {
						// setSnackBarProps({
						// 	...snackBarProp,
						// 	openSnack: true,
						// 	variant: "error",
						// 	message: "This event name already exists",
						// });
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "OTP Sent Successfully",
						});
						window.setTimeout(function() {
							navigate(RouteConstants.RESET_PWD, {
								state: { email: data.get("email") },
							});
						}, 1000);
					} else {
						setEmailErr(res.data.message);
						return;
					}
				} else {
					// disableButton.set(false);
					// setSnackBarProps({
					// 	...snackBarProp,
					// 	openSnack: true,
					// 	variant: "error",
					// 	message: "This event name already exists",
					// });
				}
				// loading.set(false);
			})
			.catch((error) => {
				console.log(error);
			});
		console.log({
			email: data.get("email"),
		});
	};

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexWrap: "wrap",

					flexDirection: { xs: "column", md: "row" },
					p: { xs: 0, md: 4 },
				}}
			>
				<Box
					sx={{
						width: { xs: "100%", md: "100%" },
						// height: "100vh",
						// height: "calc(100vh - 70px)",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: { xs: "center", md: "flex-start" },
						}}
					>
						<Typography
							component="h1"
							variant="h5"
							alignContent={"flex-start"}
							fontWeight={600}
							width={"100%"}
						>
							FORGOT PASSWORD
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit}
							sx={{ mt: 3, width: { xs: 290, md: 400 } }}
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										color="secondary"
										error={emailErr.length > 0 ? true : false}
										helperText={emailErr.length > 0 ? emailErr : ""}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
								}}
								color="inherit"
							>
								Send OTP
							</Button>
						</Box>
					</Box>
				</Box>
				{snackBarProps.openSnack && (
					<Snackbars
						{...snackBarProps}
						onClose={() =>
							setSnackBarProps({ ...snackBarProps, openSnack: false })
						}
					></Snackbars>
				)}
			</Box>
		</>
	);
};
