import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function DashboardCard(props) {
	return (
		<Card
			sx={{
				maxWidth: 300,
				height: 250,
				boxShadow: "0px 15px 55px 1px #e6e6e6",
			}}
		>
			<CardActionArea>
				<CardContent>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						textAlign={"center"}
						fontWeight={700}
						fontSize={32}
						padding={2}
						marginTop={2}
						sx={{ color: "tertiary.main" }}
					>
						{props.title}
					</Typography>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						textAlign={"center"}
						fontWeight={900}
						fontSize={45}
						padding={2}
						color="text.secondary"
						sx={{ color: "secondary.main" }}
					>
						{props.data}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
