import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";

export const Profile = () => {
	const state = useSiteState();
	const [firstNameErr, setFirstNameErr] = React.useState("");
	const [lastNameErr, setLastNameErr] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [loading, setLoading] = React.useState(true);
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [phone, setPhone] = React.useState("");

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getUserDetails = () => {
		const data = { user_id: state.user.id.get() };

		ApiHelper.post(APIURLConstants.USER_DETAILS, data)
			.then((res) => {
				console.log(res.data);
				setLoading(false);
				setFirstName(res.data.result.user.firstName);
				setLastName(res.data.result.user.lastName);
				setPhone(res.data.result.user.phone);
				setEmail(res.data.result.user.email);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSubmit = () => {
		setLoading(true);
		const data = {
			user_id: state.user.id.get(),
			firstName: firstName,
			lastName: lastName,
			phone: phone,
		};

		ApiHelper.post(APIURLConstants.UPDATE_USER, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "User details are updated",
					});
					getUserDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something is wrong",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	useEffect(() => {
		getUserDetails();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						marginLeft: { xs: 0, md: 8 },
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						User Detail
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",
							width: "70%",

							mx: 2,
						}}
						component="form"
						noValidate
						onSubmit={handleSubmit}
					>
						<Box sx={{ padding: 1 }}>
							<TextField
								autoComplete="given-name"
								name="email"
								required
								disabled
								fullWidth
								id="email"
								label="Email"
								autoComplete="email"
								autoFocus
								color="secondary"
								value={email}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<TextField
								autoComplete="given-name"
								value={firstName}
								onChange={(event) => setFirstName(event.target.value)}
								name="firstName"
								required
								fullWidth
								id="firstName"
								label="First Name"
								autoFocus
								color="secondary"
								error={firstNameErr.length > 0 ? true : false}
								helperText={firstNameErr.length > 0 ? firstNameErr : ""}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<TextField
								value={lastName}
								onChange={(event) => setLastName(event.target.value)}
								autoComplete="given-name"
								name="lastName"
								required
								fullWidth
								id="lastName"
								label="Last Name"
								autoFocus
								color="secondary"
								error={lastNameErr.length > 0 ? true : false}
								helperText={lastNameErr.length > 0 ? firstNameErr : ""}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<TextField
								value={phone}
								onChange={(event) => setPhone(event.target.value)}
								fullWidth
								id="phone"
								label="Mobile Number"
								name="phone"
								autoComplete="mobile"
								color="secondary"
								// error={mobileErr.length > 0 ? true : false}
								// helperText={mobileErr.length > 0 ? mobileErr : ""}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
								}}
								color="inherit"
							>
								Update
							</Button>
						</Box>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
