import React from "react";
import { Box } from "@mui/material";
import Logingraphic from "../../assets/logingraphic.png";
import { makeStyles } from "@mui/styles";

import { TextField, Button, Typography, Grid } from "@mui/material";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import { setSiteState } from "../../hookState/SiteState";
import { useNavigate, useLocation } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";

import validator from "validator";
import Snackbars from "../../components/Snackbar/Snackbar";

export const ResetPwd = () => {
	const state = useLocation().state.email;
	console.log("state", state);

	console.log("state", state);
	const useStyles = makeStyles((theme) => ({
		margin: {
			margin: theme.spacing(1),
		},
		socialButton: {
			margin: theme.spacing(1),
		},

		textField: {
			width: 400,
			[theme.breakpoints.down("md")]: {
				width: 320,
			},
			display: "flex",
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&:hover fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&.Mui-focused fieldset": {
					borderColor: theme.palette.secondary.main,
				},
			},
			"&:hover .MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
			"& .Mui-focused.MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
		},
		outlinedInput: {
			"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
			"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: "200px",
					height: "70px",
				},
			},
		},
	}));
	const classes = useStyles();

	let navigate = useNavigate();
	const [emailErr, setEmailErr] = React.useState("");
	const [passwordErr, setPasswordErr] = React.useState("");
	const [otpErr, setOtpErr] = React.useState("");
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		console.log("DATA", data.get("otp"));
		if (!state) {
			if (data.get("email") === "") {
				setEmailErr("Email required");
				return;
			} else {
				const email = data.get("email").trim();
				const flag = email !== "" ? validateEmail(email) : true;
				if (!flag) {
					const emlErr = flag ? "" : "Please enter valid Email";
					setEmailErr(emlErr);
					return;
				}
			}
		}

		if (data.get("password") === "") {
			setPasswordErr("Password required");
			return;
		}
		if (data.get("otp") === "") {
			setOtpErr("OTP required");
			return;
		}
		let obj = {
			email: state ? state : data.get("email"),
			otp: data.get("otp"),
			password: data.get("password"),
		};
		ApiHelper.post(APIURLConstants.PASSWORD_RESET, obj)
			.then((res) => {
				if (res.data) {
					if (res.data.success) {
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "Password Reset Successfully",
						});
						window.setTimeout(function() {
							navigate(RouteConstants.LOGIN);
						}, 1000);
					} else {
						if (res.data.password) {
							setPasswordErr(res.data.message);
							return;
						} else {
							setOtpErr(res.data.message);
							return;
						}
					}
				} else {
					// disableButton.set(false);
					// setSnackBarProps({
					// 	...snackBarProp,
					// 	openSnack: true,
					// 	variant: "error",
					// 	message: "This event name already exists",
					// });
				}
				// loading.set(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					// flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
					padding: { xs: 1, md: 4 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: "center",
						background: "#FFFFFF",
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						width: { xs: "100%", md: "70%" },
						// height: "",

						mx: "auto",
					}}
				>
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							// height: "calc(100vh - 70px)",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							justifyItems: "center",
							alignItems: "center",
							alignContent: "center",
							px: { xs: 1, md: 4 },
							my: { xs: 8 },
						}}
					>
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexWrap: "wrap",
								flexDirection: { xs: "column", md: "row" },
								p: { xs: 0, md: 4 },
							}}
						>
							<Box
								sx={{
									width: { xs: "100%", md: "100%" },
									// height: "100vh",
									// height: "calc(100vh - 70px)",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									alignContent: "center",
								}}
							>
								<Box
									sx={{
										width: "100%",
										height: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: { xs: "center", md: "flex-start" },
									}}
								>
									<Typography
										component="h1"
										variant="h5"
										alignContent={"flex-start"}
										fontWeight={600}
										width={"100%"}
									>
										RESET PASSWORD
									</Typography>
									<Box
										component="form"
										noValidate
										onSubmit={handleSubmit}
										sx={{ mt: 3, width: { xs: 290, md: 400 } }}
									>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<TextField
													required
													disabled={true}
													fullWidth
													id="email"
													label="Email Address"
													name="email"
													autoComplete="email"
													value={state ? state : ""}
													color="secondary"
													error={emailErr.length > 0 ? true : false}
													helperText={emailErr.length > 0 ? emailErr : ""}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													fullWidth
													name="password"
													label="New Password"
													type="password"
													id="password"
													autoComplete="new-password"
													color="secondary"
													error={passwordErr.length > 0 ? true : false}
													helperText={passwordErr.length > 0 ? passwordErr : ""}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													required
													fullWidth
													name="otp"
													label="OTP"
													id="otp"
													autoComplete="new-otp"
													color="secondary"
													error={otpErr.length > 0 ? true : false}
													helperText={otpErr.length > 0 ? otpErr : ""}
												/>
											</Grid>
										</Grid>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											sx={{
												mt: 3,
												mb: 2,
												color: "primary.main",
												bgcolor: "tertiary.main",
											}}
											color="inherit"
										>
											Reset Password
										</Button>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							// height: "calc(100vh - 70px)",
							marginTop: 20,
							display: { xs: "none", md: "flex" },
							flexDirection: "column",
							px: { xs: 1, md: 4 },
						}}
					>
						{/* <Typography
							fontWeight={600}
							lineHeight={"30px"}
							fontSize={24}
							color={"#6F7071"}
						>
							Welcome
						</Typography>
						<Typography fontSize={15} fontWeight={400} color={"#6F7071"}>
							Login to your account to access reports
						</Typography> */}
						<Box
							component="img"
							sx={{
								objectFit: "contain",
							}}
							src={Logingraphic}
							sx={{ marginTop: 7 }}
						/>
					</Box>
					{snackBarProps.openSnack && (
						<Snackbars
							{...snackBarProps}
							onClose={() =>
								setSnackBarProps({ ...snackBarProps, openSnack: false })
							}
						></Snackbars>
					)}
				</Box>
			</Box>
		</>
	);
};
