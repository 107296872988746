import { createState, useState } from "@hookstate/core";
import Helper from "../utillity/helper";
const initialState = {
	token: "",
	user: "",
	plan: "",
	role: "",
};

const siteState = createState(initialState);

export const setSiteState = (props) => {
	if (props) {
		siteState.set({
			...initialState,
			token: props.token,
			user: props.user,
			plan: props.plan,
			role: props.role,
		});
	}
};
export const useSiteState = () => {
	return useState(siteState);
};
export const logout = () => {
	Helper.removeStorage("userInfo");
	Helper.removeStorage("loginOption");

	siteState.set(initialState);
};
