import React, { useEffect } from "react";
import Box from "@mui/material/Box";
// import { useParams, useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { HomePageImage } from "../../components/HomePageImage/HomePageImage";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
// import { createTheme } from "@mui/material/styles";
import APIURLConstants from "../../config/config";
import ApiHelper from "../../utillity/ApiHelper";
// import RouteConstants from "../../constants/RouteConstant";
import {
	setSocialLoginState,
	useSocialLoginState,
} from "../../hookState/SocialLoginState";
import { setSiteState } from "../../hookState/SiteState";
// import { createState } from "@hookstate/core";
import Helper from "../../utillity/helper";
// const theme = createTheme();
const useStyles = makeStyles((theme) => ({
	textField: {
		"&:hover .MuiOutlinedInput-root": {
			color: theme.palette.secondary.main,
		},
		"& .Mui-focused.MuiInputLabel-root": {
			color: theme.palette.secondary.main,
		},
	},
	outlinedInput: {
		"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
			color: theme.palette.secondary.main,
		},
		"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
			color: theme.palette.secondary.main,
		},
	},
	buttonGrid: {
		width: "200px",
		height: "70px",
	},
}));
const Home = () => {
	const classes = useStyles();
	const loginOptionName = Helper.getStorage("loginOption");
	if (loginOptionName) {
		setSocialLoginState(loginOptionName);
	}
	const loginOption = useSocialLoginState();

	// console.log(loginOption.get());

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const token_id = params.get("code");
	console.log("token_id", token_id);
	const data = {
		token: token_id,
		provider: loginOption.get() && loginOption.get().name,
	};

	useEffect(() => {
		if (token_id) {
			ApiHelper.post(APIURLConstants.GOOGLE_CALLBACK, data)
				.then((res) => {
					console.log(res);
					setSiteState(res.data.result);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [token_id]);
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				<Box
					sx={{
						width: { xs: "100%", md: "50%" },
						display: "flex",
						flexDirection: "column",
						height: "calc(100vh - 70px)",
					}}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<TextField
							sx={{
								marginTop: "5rem",
								width: "70%",
								borderRadius: "30px",
								color: "secondary.main",
							}}
							label="Search"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<IconButton>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							py: 4,
						}}
					>
						<Button
							variant="contained"
							className={classes.buttonGrid}
							sx={{
								m: 1,
								color: "primary.main",
								bgcolor: "tertiary.main",
								borderRadius: 0,
							}}
						>
							Top Selling
						</Button>
						<Button
							className={classes.buttonGrid}
							variant="contained"
							sx={{
								m: 1,
								color: "primary.main",
								bgcolor: "tertiary.main",
								borderRadius: 0,
							}}
						>
							Trending
						</Button>
						<Button
							className={classes.buttonGrid}
							variant="contained"
							sx={{
								m: 1,
								color: "primary.main",
								bgcolor: "tertiary.main",
								borderRadius: 0,
							}}
						>
							Budget
						</Button>
					</Box>
				</Box>
				<Box
					sx={{
						width: { xs: "100%", md: "50%" },
						// height: "calc(100vh - 70px)",
						display: "flex",
						color: "secondary.main",
					}}
				>
					<HomePageImage />
				</Box>
			</Box>
		</>
	);
};

export default Home;
