import React from "react";
import { Box } from "@mui/material";

// import Signupgraphic from "../../assets/signupgraphic.png";
import Logingraphic from "../../assets/logingraphic.png";

import { Typography } from "@mui/material";
import { LoginDetails } from "./LoginDetails";
import { none } from "@hookstate/core";

export const Login = () => {
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					// flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
					padding: { xs: 1, md: 4 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: "center",
						background: "#FFFFFF",
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						width: { xs: "100%", md: "70%" },
						// height: "",

						mx: "auto",
					}}
				>
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							// height: "calc(100vh - 70px)",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							justifyItems: "center",
							alignItems: "center",
							alignContent: "center",
							px: { xs: 1, md: 4 },
							my: { xs: 8 },
						}}
					>
						<LoginDetails />
					</Box>
					<Box
						sx={{
							width: { xs: "100%", md: "50%" },
							// height: "calc(100vh - 70px)",
							marginTop: 14,
							display: { xs: "none", md: "flex" },
							flexDirection: "column",
							px: { xs: 1, md: 4 },
						}}
					>
						{/* <Typography
							fontWeight={600}
							lineHeight={"30px"}
							fontSize={24}
							color={"#6F7071"}
						>
							Welcome
						</Typography>
						<Typography fontSize={15} fontWeight={400} color={"#6F7071"}>
							Login to your account to access reports
						</Typography> */}
						<Box
							component="img"
							sx={{
								objectFit: "contain",
							}}
							src={Logingraphic}
							sx={{ marginTop: 5 }}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
};
