import React from "react";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RouteConstants from "../../constants/RouteConstant";
import { makeStyles } from "@mui/styles";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PaymentIcon from "@mui/icons-material/Payment";
import { alpha } from "@mui/material";
import { setProfileState, useProfileState } from "../../hookState/ProfileState";
import { logout, useSiteState } from "../../hookState/SiteState";
import { isEmpty } from "lodash";
import { setCartState } from "../../hookState/CartState";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import RssFeedOutlinedIcon from "@mui/icons-material/RssFeedOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PasswordIcon from "@mui/icons-material/Password";
const useStyles = makeStyles(() => ({
	nopointer: {
		textDecoration: "none",
	},
}));
export const Navbar = (props) => {
	const classes = useStyles();
	const profileState = useProfileState();
	let navigate = useNavigate();

	const state = useSiteState();
	// console.log("state====", state);
	// console.log("emptyyty", isEmpty(state.role.get()));
	const handleLogoutClose = () => {
		console.log("PRops", props);
		if (props.handleCallback) {
			props.handleCallback();
		}
		setProfileState(false);
		logout();
		setCartState([]);
		navigate(RouteConstants.LANDING);
	};

	return (
		<Box
			sx={{
				height: "100%",
				minHeight: "calc(100vh - 70px);",
				// bgcolor: 'primary.main',
				// bgcolor: alpha("#06255B", 0.15),
				// opacity: 0.3,
				// color: "fff",
				fontWeight: "500",
				width: 350,
				".MuiSvgIcon-root": {
					fill: "#FFAC1C",
				},
			}}
		>
			<List
				sx={{
					width: "100%",
				}}
				component="nav"
			>
				{state.role.get() === "admin" ? (
					<>
						<Link
							to={RouteConstants.DASHBOARD}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<DashboardOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Dashboard" />
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.ALL_USER}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<AccountCircleOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Users" />
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.FEED_LIST}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<RssFeedOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Feed List" />
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.ADMIN_FEED}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<RssFeedOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Add Feed" />
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.ADMIN_FAQ}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<QuestionMarkOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="FAQ" />
							</ListItemButton>
						</Link>
					</>
				) : (
					<>
						<Link
							to={RouteConstants.PROFILE}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<ManageAccountsIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Details" />
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.CHANGE_PWD}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<PasswordIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Change password" />
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.SUBSCRIPTION}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<PaymentIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText
									primary={
										state.user.get() !== "" &&
										state.plan.get() !== "" &&
										state.plan.get() !== undefined &&
										state.plan.get() !== null
											? "Subscription Detail"
											: "Payment History"
									}
								/>
							</ListItemButton>
						</Link>
						<Link
							to={RouteConstants.CARD_LIST}
							className={classes.nopointer}
							onClick={() => {
								props.handleCallback();
							}}
						>
							<ListItemButton>
								<ListItemIcon>
									<AddCardOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Card" />
							</ListItemButton>
						</Link>
						{/* <Link to={RouteConstants.ADD_CARD} className={classes.nopointer}>
							<ListItemButton>
								<ListItemIcon>
									<AddCardOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Add Card" />
							</ListItemButton>
						</Link> */}
					</>
				)}

				<ListItemButton onClick={handleLogoutClose}>
					<ListItemIcon>
						<LogoutOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItemButton>
			</List>
		</Box>
	);
};
