import React from "react";
import { Box } from "@mui/material";
// import Signupgraphic from "../../assets/signupgraphic.png";
import Logingraphic from "../../assets/logingraphic.png";
import {
	Typography,
	Grid,
	TextField,
	TextareaAutosize,
	Button,
} from "@mui/material";
import Helper from "../../utillity/helper";
import RouteConstants from "../../constants/RouteConstant";
import APIURLConstants from "../../config/config";
import ApiHelper from "../../utillity/ApiHelper";
import validator from "validator";
import Thanks from "../../assets/thanks.png";
import Contact from "../../assets/contact.png";
import { CircularProgress } from "@mui/material";
import Snackbars from "../../components/Snackbar/Snackbar";
export const ContactUs = () => {
	const [fullNameErr, setFullNameErr] = React.useState("");
	const [emailErr, setEmailErr] = React.useState("");
	const [subjErr, setSubjErr] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [contactFlag, setContactFlag] = React.useState(false);

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		// setRegData(new FormData(event.currentTarget));
		console.log("Data====>", data.get("fullName"));
		console.log("Data 2====>", data.get("subject"));
		if (data.get("fullName") === "") {
			setFullNameErr("Full Name required");
			return;
		}

		if (data.get("email") === "") {
			setEmailErr("Email required");
			return;
		} else {
			const email = data.get("email").trim();
			const flag = email !== "" ? validateEmail(email) : true;
			if (!flag) {
				const emlErr = flag ? "" : "Please enter valid Email";
				setEmailErr(emlErr);
				return;
			}
		}
		setLoading(true);

		ApiHelper.post(APIURLConstants.POST_CONTACT, data)
			.then((res) => {
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Thank you for contacting us",
					});
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something Wrong",
					});
				}
				setContactFlag(true);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					// flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
					padding: { xs: 1, md: 4 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: { xs: "column", md: "row" },
						background: "#FFFFFF",
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						width: "90%",
						// height: "calc(100vh - 100px)",

						mx: "auto",
					}}
				>
					{contactFlag ? (
						<Box
							component="img"
							src={Thanks}
							sx={{
								mx: "auto",
								padding: 0.5,
								objectFit: "contain",
								height: { xs: "150px", md: "280px" },
							}}
						/>
					) : (
						<>
							<Box
								sx={{
									display: { xs: "flex", md: "none" },
									flexDirection: { xs: "column", md: "row" },
									justifyContent: "center",
									px: { xs: 1, md: 5 },
									alignItems: "center",
									alignContent: "center",
								}}
							>
								<Box
									component="form"
									noValidate
									onSubmit={handleSubmit}
									sx={{
										display: "flex",
										flexDirection: { xs: "column", md: "row" },
										alignContent: "center",
										justifyContent: "center",
										width: { xs: "70%", md: "50%" },
									}}
								>
									<Box>
										<Typography
											fontSize={18}
											fontWeight={600}
											color="initial"
											align="center"
										>
											Contact Us
										</Typography>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<TextField
													autoComplete="given-name"
													name="fullName"
													required
													fullWidth
													id="fullName"
													label="Full Name"
													autoFocus
													color="secondary"
													error={fullNameErr.length > 0 ? true : false}
													helperText={fullNameErr.length > 0 ? fullNameErr : ""}
												/>
											</Grid>

											<Grid item xs={12}>
												<TextField
													required
													fullWidth
													id="email"
													label="Email Address"
													name="email"
													autoComplete="email"
													color="secondary"
													error={emailErr.length > 0 ? true : false}
													helperText={emailErr.length > 0 ? emailErr : ""}
												/>
											</Grid>

											<Grid item xs={12}>
												<TextField
													required
													fullWidth
													id="subject"
													label="Type message"
													name="subject"
													autoComplete="subject"
													color="secondary"
													error={subjErr.length > 0 ? true : false}
													helperText={subjErr.length > 0 ? subjErr : ""}
												/>
												{/* <TextareaAutosize
													name="subject"
													id="subject"
													aria-label="minimum height"
													minRows={6}
													placeholder="Subject"
													style={{ width: 240 }}
													// error={subjErr.length > 0 ? true : false}
													// helperText={subjErr.length > 0 ? emailErr : ""}
												/> */}
											</Grid>
										</Grid>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
											}}
										>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												sx={{
													mt: 3,
													mb: 2,
													color: "primary.main",
													bgcolor: "tertiary.main",
													width: "121px",
												}}
												color="inherit"
											>
												{loading ? <CircularProgress /> : "Submit"}
											</Button>
										</Box>
									</Box>
									<Box
										component="img"
										src={Contact}
										sx={{
											padding: 0.5,
											objectFit: "contain",
											height: { xs: "220px", md: "280px" },
										}}
									/>
								</Box>
							</Box>

							{/* For Deskotop */}

							<Box
								sx={{
									display: { xs: "none", md: "flex" },
									flexDirection: "row-reverse",
									alignItems: "center",
									alignContent: "center",
									justifyContent: "center",
									mx: "auto",
									width: "100%",
								}}
							>
								<Box
									component="img"
									src={Contact}
									sx={{
										// padding: 0.5,
										objectFit: "contain",
										height: "280px",
									}}
								/>
								<Box component="form" noValidate onSubmit={handleSubmit}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											paddingTop: 12,
											gap: 1,
										}}
									>
										<Typography
											fontSize={18}
											fontWeight={600}
											color="initial"
											align="center"
											textAlign={"center"}
										>
											Contact Us
										</Typography>
										<Box sx={{ width: "100%" }}>
											<TextField
												autoComplete="given-name"
												name="fullName"
												required
												fullWidth
												id="fullName"
												label="Full Name"
												autoFocus
												color="secondary"
												error={fullNameErr.length > 0 ? true : false}
												helperText={fullNameErr.length > 0 ? fullNameErr : ""}
											/>
										</Box>
										<Box sx={{ width: "100%" }}>
											<TextField
												required
												fullWidth
												id="email"
												label="Email Address"
												name="email"
												autoComplete="email"
												color="secondary"
												error={emailErr.length > 0 ? true : false}
												helperText={emailErr.length > 0 ? emailErr : ""}
											/>
										</Box>

										<Box sx={{ width: "450px" }}>
											{/* <TextareaAutosize
												name="subject"
												id="subject"
												aria-label="minimum height"
												minRows={6}
												placeholder="Subject"
												style={{ width: 360 }}
												error={subjErr.length > 0 ? true : false}
												helperText={subjErr.length > 0 ? emailErr : ""}
											/> */}
											<TextField
												required
												fullWidth
												id="subject"
												label="Type message"
												name="subject"
												autoComplete="subject"
												color="secondary"
												error={subjErr.length > 0 ? true : false}
												helperText={subjErr.length > 0 ? subjErr : ""}
											/>
										</Box>

										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
											}}
										>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												sx={{
													mt: 3,
													mb: 2,
													color: "primary.main",
													bgcolor: "tertiary.main",
													width: "121px",
												}}
												color="inherit"
											>
												{loading ? <CircularProgress /> : "Submit"}
											</Button>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Box>
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</>
	);
};
