import React from "react";
import { Accordion, Box } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import APIURLConstants from "../../config/config";

import ApiHelper from "../../utillity/ApiHelper";
export const Faq = () => {
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const getAllFAQ = () => {
		ApiHelper.get(APIURLConstants.GET_FAQ)
			.then((res) => {
				console.log(res.data.result.faq);

				console.log(res.data);
				setData(res.data.result.faq);

				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);

				console.log(error);
			});
	};
	React.useEffect(() => {
		getAllFAQ();
	}, []);
	return (
		<>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignContent: "center",
						justifySelf: "center",
					}}
				>
					<Box
						sx={{
							p: 4,
							width: { xs: "100%", md: "60%" },
						}}
					>
						<Box>
							<Typography
								fontSize={18}
								fontWeight={600}
								color="initial"
								align="center"
							>
								FAQ
							</Typography>
						</Box>
						{data.length > 0 ? (
							data.map((key, index) => {
								return (
									<Accordion sx={{ p: 1 }} expanded={true} key={index}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls={"panel" + index + "content"}
											id={"panel" + index + "header"}
											sx={{ backgroundColor: "aliceblue" }}
										>
											<Typography>{key.question}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography>{key.answer}</Typography>
										</AccordionDetails>
									</Accordion>
								);
							})
						) : (
							<Box>
								<Typography>No FAQ Found</Typography>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</>
	);
};
