import { Box, Typography } from "@mui/material";
import React from "react";
import { PriceCard } from "../../components/PriceCard/PriceCard";
import { useSiteState } from "../../hookState/SiteState";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

export const Price = () => {
	const state = useSiteState();
	console.log("state", state);
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					justifyContent: "space-between",
					// flexWrap: { md: "wrap" },
					p: 1,
					paddingTop: 4,
					mx: { xs: 2, md: 5 },
				}}
			>
				<Box sx={{ display: "flex", paddingTop: 1 }}>
					<LocalAtmIcon
						fontSize="large"
						sx={{ color: "tertiary.main", mx: 1 }}
					/>
					<Typography fontSize={24} fontWeight={500} marginRight={1}>
						Pricing
					</Typography>
				</Box>
				<Box>
					<Typography
						fontSize={{ sx: 20, md: 26 }}
						textAlign={"center"}
						fontWeight={500}
						marginTop={0.5}
					>
						Simple pricing models to get you started
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: { xs: "center" },
					}}
				></Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					flexWrap: "wrap",
					justifyContent: "center",
					alignContent: "center",
					// p: 3,
					gap: 1,
				}}
			>
				<PriceCard
					colorParams={"#FFAC1C"}
					reportTitle="Premium Access"
					type="unlimited"
					totalReport={0}
					reportPrice={1}
					buttonTilte={"Subscribe Now"}
					showButton={true}
				></PriceCard>
				<PriceCard
					colorParams={"#0081FF"}
					reportTitle="Single Access"
					type="single"
					totalReport={0}
					reportPrice={25}
					showButton={false}
				></PriceCard>
			</Box>
		</>
	);
};
