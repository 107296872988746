import { Typography, Box } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import React from "react";
import RouteConstants from "../../constants/RouteConstant";
const useStyles = makeStyles({
	nopointer: {
		mx: 2,
		textDecoration: "none",
	},
});
export const Footer = () => {
	const classes = useStyles();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				justifyContent: "space-between",
				height: "40px",
				px: { xs: 2, md: 5 },
				py: { xs: 2 },
				alignItems: "center",
				// background: "#F5F5F5",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<Link
					color="black"
					to={RouteConstants.FAQ}
					className={classes.nopointer}
				>
					<Typography color="initial" fontSize={14} paddingX={1}>
						FAQ
					</Typography>
				</Link>
				<Link
					color="black"
					to={RouteConstants.PRIVACY}
					className={classes.nopointer}
				>
					<Typography color="initial" fontSize={14} paddingX={2}>
						Privacy Statement
					</Typography>
				</Link>
				<Link
					color="black"
					to={RouteConstants.CONTACT}
					className={classes.nopointer}
				>
					<Typography color="initial" fontSize={14}>
						Contact Us
					</Typography>
				</Link>
			</Box>
			<Box>
				<Typography fontSize={14} fontWeight={400}>
					Copyright &copy; 2022 Ecom Insider.
				</Typography>
			</Box>
		</Box>
	);
};
