import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import { Button, Box } from "@mui/material";
// import Blur from "../../assets/blur.svg";
// import nBlur from "../../assets/nblur.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import { makeStyles } from "@mui/styles";
import Snackbars from "../Snackbar/Snackbar";
import Helper from "../../utillity/helper";
import { useCartState, setCartState } from "../../hookState/CartState";
import RouteConstants from "../../constants/RouteConstant";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
const useStyles = makeStyles({
	cartBtn: {
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#FFAC1C",
			boxShadow: "0px 2px 12px #FFAC1C",
		},
	},
	buyBtn: {
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#b32419d1",
			boxShadow: "0px 2px 12px #b32419d1",
		},
	},
});
export default function ProductCard(props) {
	const state = useSiteState();

	const classes = useStyles();
	const cartState = useCartState();
	const [cartAdded, setCartAdded] = React.useState(0);
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	// const { price_symbol, annualPrice, asin } = props.data;
	// console.log("data", props.data.price_symbol);

	const checkProductExist = (p_asin) => {
		if (p_asin && props.cartAdded && props.cartAdded.length > 0) {
			const cartStatus = props.cartAdded.find((key) => key.asin === p_asin)
				.cart_status_id;
			setCartAdded(cartStatus);
		}
		return;
	};
	const navigate = useNavigate();
	const addToCart = (productData) => {
		console.log("cartState", cartState);
		if (state.user.get() !== "") {
			const productInfo = {
				asin: productData.asin,
				user_id: state.user.id.get(),
			};

			props.addCartItem(productInfo);
		} else {
			props.addItem(productData);
		}
	};

	const redirectPageInfo = (productData) => {
		navigate(RouteConstants.PRODUCTINFO, {
			state: { product: productData },
		});
	};
	const handlePayment = (price, data) => {
		let cartData = [];
		cartData.push(data);
		navigate(RouteConstants.PAYMENT_GATEWAY, {
			state: { amount: price, cart: cartData, type: "buyItNow" },
		});
	};
	const removeCard = (product_asin) => {
		props.removeProduct(product_asin);
	};

	return (
		<Box sx={{ m: { xs: 1, md: 1.5 }, zIndex: 999 }}>
			<Card
				sx={{
					display: "flex",
					// width: "376px",
					width: { xs: "80%", md: "376px" },
					height: "180px",
					borderRadius: "12px",
					border: "1px solid #0072B1",
					// boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.25)",
					boxShadow: " 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)",
					margin: { xs: "auto", md: "0" },
					justifyContent: "space-between",
				}}
			>
				<CardContent
					sx={{ width: "232px", display: "flex", flexDirection: "column" }}
				>
					<Box>
						<Box sx={{ display: "flex", flexDirection: "column", p: 3 }}>
							<Typography
								fontSize={18}
								fontWeight={600}
								color={"secondary.main"}
							>
								{/* ${Math.floor(Math.random() * 100000) + 1} */}
								{props.data &&
									props.data.price_symbol &&
									props.data.price_symbol + " "}{" "}
								{props.data && props.data.annualPrice && props.data.annualPrice}
							</Typography>
							<Typography fontSize={14} fontWeight={500}>
								Product income
							</Typography>
						</Box>
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							{props.myAccount === true ? (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										paddingLeft: 1,
									}}
								>
									<Button
										variant="contained"
										sx={{
											backgroundColor: "tertiary.main",
											color: "primary.main",
											borderRadius: "4px",
											height: "34px",
											width: "160px",
											textTransform: "capitalize",
											marginLeft: "14px",
										}}
										onClick={() => {
											redirectPageInfo(props.data);
										}}
									>
										<ArticleIcon sx={{ fontSize: 20, mx: 1 }} />
										See Report
									</Button>
								</Box>
							) : (
								<Box
									sx={{ display: "flex", flexDirection: "row", paddingLeft: 1 }}
								>
									{/* <ShoppingCartIcon
										sx={{ fontSize: 38, cursor: "pointer" }}
										onClick={() => addToCart(props.data)}
									/> */}
									<Button
										className={classes.cartBtn}
										variant="contained"
										sx={{
											backgroundColor: "tertiary.main",
											color: "primary.main",
											borderRadius: "4px",
											height: "34px",
											// width: "100px",
											width: { xs: "90px", md: "115px" },
											textTransform: "capitalize",
											marginLeft: "5px",
											fontSize: { xs: "0.65rem", md: "0.7rem" },
											padding: { xs: "3px 3px", md: "6px 8px" },
										}}
										onClick={() => addToCart(props.data)}
									>
										{/* {props.checkProductExist(asin) === 1 ||
												(props.checkProductExist(asin) === 2 && (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="icon icon-tabler icon-tabler-check"
														width="15"
														height="15"
														viewBox="0 0 24 24"
														stroke-width="2"
														stroke="#ffffff"
														fill="none"
														stroke-linecap="round"
														stroke-linejoin="round"
														style={{ marginRight: "5px" }}
													>
														<path stroke="none" d="M0 0h24v24H0z" fill="none" />
														<path d="M5 12l5 5l10 -10" />
													</svg>
												))} */}

										{/* {props.checkProductExist(asin) === 1 ||
										props.checkProductExist(asin) === 2
											? "Added in Cart"
											: "Add to Cart"} */}
										{state.user.get() !== "" &&
										state.plan.get() !== "" &&
										state.plan.get() !== undefined &&
										state.plan.get() !== null
											? "Add to Account"
											: "Add to Cart"}
									</Button>
									{state.user.get() !== "" &&
									state.plan.get() !== "" &&
									state.plan.get() !== null &&
									state.plan.get() !== undefined ? null : (
										<Button
											className={classes.buyBtn}
											variant="contained"
											sx={{
												// backgroundColor: "secondary.main",
												backgroundColor: "#e43023d1",
												color: "primary.main",
												borderRadius: "4px",
												height: "34px",
												width: { xs: "90px", md: "115px" },
												textTransform: "capitalize",
												marginLeft: "5px",
												zIndex: 9999,
												fontSize: "0.7rem",
												padding: "6px 8px",
											}}
											onClick={() => {
												handlePayment(25, props.data);
											}}
										>
											Buy It Now
											{/* {props.checkProductExist(asin) === 3
											? "already purchased"
											: "Buy It Now"} */}
										</Button>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</CardContent>

				<Box
					sx={{
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						alignContent: "center",
					}}
				>
					<CardMedia
						component="img"
						sx={{
							width: "125px",
							height: "125px",
							objectFit: "contain",
							filter: props.myAccount
								? "blur(0px)"
								: state.user.get() !== "" &&
								  state.plan.get() !== "" &&
								  state.plan.get() !== undefined &&
								  state.plan.get() !== null
								? "blur(0px)"
								: "blur(8px)",
						}}
						image={props.data && props.data.image}
						alt="product image"
					/>
				</Box>
				<Box
					sx={
						{
							// display: "flex",
							// flexDirection: "row-reverse",
						}
					}
				>
					{/* {props.myAccount && (
						<IconButton
							aria-label="delete"
							onClick={() => {
								removeCard(props.data.asin);
							}}
						>
							<DeleteIcon />
						</IconButton>
					)} */}
				</Box>
			</Card>
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
}
