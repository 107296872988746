import React from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import validator from "validator";
import {
	CardElement,
	useStripe,
	useElements,
	// Elements,
	// CardNumberElement,
	// PaymentElement,
} from "@stripe/react-stripe-js";
import Box from "@mui/material/Box";
import { Button, Typography, Grid, TextField, Chip } from "@mui/material";
import Divider from "@mui/material/Divider";

// import StripeInput from "./StripeInput";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import RouteConstants from "../../constants/RouteConstant";
import {
	useSiteState,
	setSiteState,
	setSitePlanState,
} from "../../hookState/SiteState";
import Helper from "../../utillity/helper";
import { setCartState } from "../../hookState/CartState";
import Snackbars from "../../components/Snackbar/Snackbar";

export const CardForm = (props) => {
	const state = useSiteState();

	let navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = React.useState("");

	const [loading, setLoading] = React.useState(false);
	const [loading1, setLoading1] = React.useState(false);
	const [priceType, setPriceType] = React.useState({
		price: "",
		type: "",
	});

	const [firstNameErr, setFirstNameErr] = React.useState("");
	const [lastNameErr, setLastNameErr] = React.useState("");
	const [emailErr, setEmailErr] = React.useState("");
	const [passwordErr, setPasswordErr] = React.useState("");

	const [customer, setCustomer] = React.useState("");

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		if (state.user.get() === "") {
			setCustomer(state.user.get().provider);
			setError("");
			if (elements == null) {
				console.log("1");
				return;
			}
		} else {
			console.log("state.user.get().provider", state.user.get());
		}
		setLoading(true);
		let obj = {};

		state.user.get() === ""
			? (obj = {
					firstName: data.get("firstName"),
					lastName: data.get("lastName"),
					email: data.get("email"),
					password: data.get("password"),
					user_id: 0,
			  })
			: (obj = { user_id: state.user.id.get(), email: state.user.email.get() });
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			// customer: customer,
			card: elements.getElement(CardElement),
		});
		if (!error) {
			try {
				const { id } = paymentMethod;
				console.log(id);
				const response = await ApiHelper.post(APIURLConstants.SAVE_CARD, {
					token: paymentMethod,
					id: paymentMethod.id,
					data: obj,
				});
				if (response.data.success) {
					console.log("Successful payment", response);
					setLoading(false);
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Card Added Successfully",
					});
					window.setTimeout(function() {
						props.callBackAddCard();
					}, 2000);
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: response.data.message,
					});
					return;
				}
			} catch (error) {
				setLoading(false);
				console.log("Error", error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: error,
				});
				setError(error);
			}
			//send token to backend here
		} else {
			setLoading(false);
			setError(error.message);
		}
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					p: 2,
				}}
			>
				{loading && (
					<Box sx={{ display: "flex", width: "100%", height: "100%" }}>
						<CircularProgress />
					</Box>
				)}
				<Box
					component="form"
					onSubmit={handleSubmit}
					sx={{
						mt: 3,
						width: { xs: "100%", md: "100%" },
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						padding: 2,
					}}
				>
					{error && (
						<Typography
							fontSize={15}
							color="initial"
							sx={{ color: "#FF0000", paddingBottom: 1 }}
						>
							{error}
						</Typography>
					)}

					<Box
						sx={{
							bordeRadius: "4px",
							border: "1px solid #0072B1	",
							py: "16.5px",
							px: "14px",
						}}
					>
						<CardElement options={{ hidePostalCode: true }} />
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							mx: "auto",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: props.type !== "unlimited" ? "50%" : "100%",
								mx: "auto",
							}}
						>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
									width: "60%",
									mx: "auto",
								}}
								color="inherit"
							>
								{loading ? <CircularProgress /> : "Save"}
							</Button>
						</Box>
					</Box>
				</Box>

				{/* <form onSubmit={handleSubmit}>
				<button type="submit" disabled={!stripe || !elements}>
					Pay
				</button>
				muiBu
			</form> */}
				{snackBarProps.openSnack && (
					<Snackbars
						{...snackBarProps}
						onClose={() =>
							setSnackBarProps({ ...snackBarProps, openSnack: false })
						}
					></Snackbars>
				)}
			</Box>
		</>
	);
};
