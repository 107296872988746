import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { logout, useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import { setProfileState } from "../../hookState/ProfileState";
import { setCartState } from "../../hookState/CartState";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";

export const ChangePwd = () => {
	const state = useSiteState();
	// const [oldPwdErr, setOldPwdErr] = React.useState("");
	const [newPwdErr, setNewPwdErr] = React.useState("");
	const [confPwdErr, setConfPwdErr] = React.useState("");
	// const [lastNameErr, setLastNameErr] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [loading, setLoading] = React.useState(true);
	// const [oldPwd, setOldPwd] = React.useState("");
	const [newPwd, setNewPwd] = React.useState("");
	const [confPwd, setConfPwd] = React.useState("");
	let navigate = useNavigate();
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getUserDetails = () => {
		const data = { user_id: state.user.id.get() };

		ApiHelper.post(APIURLConstants.USER_DETAILS, data)
			.then((res) => {
				console.log(res.data);
				setLoading(false);

				setEmail(res.data.result.user.email);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		// const data1 = new FormData(event.currentTarget);

		// if (oldPwd === "") {
		// 	setOldPwdErr("Please enter Old Password ");
		// 	return;
		// }
		if (newPwd === "") {
			setNewPwdErr("Please enter New Password ");
			return;
		}
		if (confPwd === "") {
			setConfPwdErr("Please enter Confirm Password ");
			return;
		}
		if (newPwd !== confPwd) {
			setConfPwdErr("Confirm password does not match");
			return false;
		}

		setLoading(true);

		const data = {
			user_id: state.user.id.get(),
			// oldPwd: oldPwd,
			newPwd: newPwd,
			confPwd: confPwd,
		};

		ApiHelper.post(APIURLConstants.UPDATE_USER_PWD, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "User details are updated",
					});

					setProfileState(false);
					logout();
					setCartState([]);
					window.setTimeout(function() {
						navigate(RouteConstants.LOGIN);
					}, 3000);
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something is wrong",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	useEffect(() => {
		getUserDetails();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
						marginLeft: { xs: 0, md: 8 },
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						Change Password
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",
							width: "70%",

							mx: 2,
						}}
						component="form"
						noValidate
						onSubmit={handleSubmit}
					>
						<Box sx={{ padding: 1 }}>
							<TextField
								autoComplete="given-name"
								name="email"
								required
								disabled
								fullWidth
								id="email"
								label="Email"
								autoComplete="email"
								autoFocus
								color="secondary"
								value={email}
							/>
						</Box>
						{/* <Box sx={{ padding: 1 }}>
							<TextField
								type="password"
								autoComplete="given-name"
								value={oldPwd}
								onChange={(event) => setOldPwd(event.target.value)}
								name="oldPassword"
								required
								fullWidth
								id="oldPassword"
								label="Old Password"
								autoFocus
								color="secondary"
								error={oldPwdErr.length > 0 ? true : false}
								helperText={oldPwdErr.length > 0 ? oldPwdErr : ""}
							/>
						</Box> */}
						<Box sx={{ padding: 1 }}>
							<TextField
								type="password"
								value={newPwd}
								onChange={(event) => setNewPwd(event.target.value)}
								autoComplete="given-name1"
								name="newPassword"
								required
								fullWidth
								id="newPassword"
								label="New Password"
								// autoFocus
								color="secondary"
								error={newPwdErr.length > 0 ? true : false}
								helperText={newPwdErr.length > 0 ? newPwdErr : ""}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<TextField
								type="password"
								value={confPwd}
								onChange={(event) => setConfPwd(event.target.value)}
								fullWidth
								id="confPassword"
								label="Confirm Password"
								name="confPassword"
								autoComplete="confirmPassword"
								color="secondary"
								error={confPwdErr.length > 0 ? true : false}
								helperText={confPwdErr.length > 0 ? confPwdErr : ""}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
								}}
								color="inherit"
							>
								Change Password
							</Button>
						</Box>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
