import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import HomeSideBar from "../../assets/home.png";
// import ProductCard from "../ProductCard/ProductCard";

const useStyles = makeStyles({
	bgImage: {
		background: "#4167b2",
		backgroundImage: `url(${HomeSideBar})`,
		backgroundPosition: "center",

		backgroundSize: "cover",
		color: "#ffffff",
		padding: "1em",
		textAlign: "center",
		width: "100%",
	},
});
export const HomePageImage = () => {
	const classes = useStyles();

	return (
		<>
			{/* <Box
				component="img"
				alt="HomeSideBAr"
				sx={{
					objectFit: "contain",
					width: "100%",
					bgcolor: "blue",
					background: "linear-gradient(to right bottom, #4167b2, #4c6fb6)",
				}}
				src={HomeSideBar}
			/> */}
			<Box className={classes.bgImage}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						height: "100%",
					}}
				>
					{/* <ProductCard />
					<ProductCard />
					<ProductCard /> */}
				</Box>

				{/* <ProductCard /> */}

				{/* <ProductCard />
				<ProductCard /> */}
			</Box>
		</>
	);
};
