import React from "react";
import { Box } from "@mui/material";
// import Signupgraphic from "../../assets/signupgraphic.png";
import Logingraphic from "../../assets/logingraphic.png";
import { Typography } from "@mui/material";
import { typography } from "@mui/system";

export const Privacy = () => {
	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					// flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },
					padding: { xs: 2, md: 4 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						// flexWrap: "wrap",
						// flexDirection: "center",
						background: "#FFFFFF",
						// boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						width: { xs: "100%", md: "80%" },
						height: "100%",

						mx: "auto",
					}}
				>
					<Box
						sx={{
							// width: { xs: "100%", md: "100%" },
							// height: "calc(100vh - 70px)",

							display: "flex",
							flexDirection: { xs: "column", md: "column" },
							p: { xs: 0, md: 4 },
							height: "100%",
							// alignItems: "center",
							// alignContent: "center",
							// justifyContent: "center",
						}}
					>
						<Typography
							fontSize={18}
							fontWeight={600}
							color="initial"
							align="center"
						>
							Privacy Policy
						</Typography>
						<Typography fontSize={15} color={"initial"}>
							This Privacy Policy applies to our website, Ecom Insider, which is
							a Software as a Service platform that sells consumer data. We take
							the privacy of our users seriously and are committed to protecting
							their personal information. This policy explains how we collect,
							use, disclose, and protect personal information through our
							website.
						</Typography>
						<Typography fontSize={15} color="initial" py={2}>
							Collection of Personal Information
						</Typography>
						<Typography fontSize={15} color="initial">
							We collect personal information from our users when they use our
							website to purchase consumer data. The personal information we
							collect may include name, email address, and payment information.
							We use this information to process payments and provide our users
							with access to the consumer data they have purchased.
						</Typography>
						<Typography fontSize={15} color="initial" py={2}>
							Use of Personal Information
						</Typography>
						<Typography fontSize={15} color={"initial"}>
							We use the personal information we collect to provide our
							services, improve our website, and communicate with our users. For
							example, we may use your email address to send you updates about
							the consumer data you have purchased, or to send you promotional
							materials if you have opted in to receive them.
						</Typography>
						<Typography fontSize={15} color="initial" py={2}>
							Disclosure of Personal Information
						</Typography>
						<Typography fontSize={15} color="initial">
							We will not disclose personal information to third parties without
							your consent, except as required by law. However, we may share
							personal information with service providers who assist us in
							providing our services, such as payment processors. These service
							providers are subject to strict data protection obligations and
							are only permitted to use your personal information to provide
							services to us.
						</Typography>
						<Typography fontSize={15} color="initial" py={2}>
							Data Retention
						</Typography>
						<Typography fontSize={15} color="initial">
							The consumer data we sell is updated on a scheduled basis, but
							users should do their own due diligence. We retain personal
							information for as long as necessary to provide our services and
							comply with our legal obligations.
						</Typography>

						<Typography fontSize={15} color="initial" py={2}>
							Security
						</Typography>

						<Typography fontSize={15} color="initial">
							We use appropriate security measures to protect personal
							information from unauthorized access, alteration, or destruction.
							However, no method of data transmission or storage is completely
							secure, and we cannot guarantee the security of personal
							information transmitted through our website.
						</Typography>

						<Typography fontSize={15} color="initial" py={2}>
							General Data Protection Regulation (GDPR)
						</Typography>

						<Typography fontSize={15} color="initial">
							Our website is GDPR compliant. We process personal information in
							accordance with the GDPR, including obtaining user consent where
							required. We will provide access to personal information upon
							request and allow individuals to correct, amend, or delete their
							personal information.
						</Typography>

						<Typography fontSize={15} color="initial" py={2}>
							Changes to this Privacy Policy
						</Typography>

						<Typography fontSize={15} color="initial">
							We reserve the right to update this Privacy Policy from time to
							time. We will provide notice of any changes to this Privacy Policy
							by updating the "Last Updated" date at the top of this page. Your
							continued use of our website following any changes to this Privacy
							Policy will indicate your acceptance of such changes.
						</Typography>

						<Typography fontSize={15} color="initial" py={2}>
							Contact Us
						</Typography>

						<Typography fontSize={15} color="initial">
							If you have any questions or concerns about this Privacy Policy,
							or our handling of your personal information, please contact us
							through the contact us page.
						</Typography>

						<Typography fontSize={15} color="initial" py={2}>
							Disclaimer:
						</Typography>

						<Typography fontSize={15} color="initial" paddingBottom={2}>
							The data we provide is regularly updated and may not accurately
							reflect the market as the market is always changing.
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};
