import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Typography, Box, Tooltip } from "@mui/material";
import Feed from "../../assets/feed.png";
import Feed2 from "../../assets/feed2.png";
import RouteConstants from "../../constants/RouteConstant";
// import Feed3 from "../../assets/feed3.png";

export const FeedCard = (props) => {
	const [url, setUrl] = React.useState("");
	const [flag, setFlag] = React.useState("");

	useEffect(() => {
		let url1 = "";
		const match = props.data && props.data.post.match(/<img src[^>]+>/);
		if (match) {
			const result = match[0];
			console.log("result", result);
			if (result) {
				var myRegex = /<img[^>]+src="(https:\/\/[^">]+)"/g;

				url1 = myRegex.exec(result)[1];
				console.log(url1);

				setUrl(url1);
				setFlag("yes");
			}
		} else {
			setFlag("no");
		}
	}, [props.data]);

	// console.log(url);
	const createMarkup = () => {
		return { __html: props.data && props.data.post };
	};

	const navigate = useNavigate();
	return (
		<Box sx={{ m: { xs: 0, md: 2 } }}>
			<Card
				sx={{
					width: { xs: 290, md: 376 },
					height: 300,
					border: "1px solid #0072B1",
					// boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.25)",
					boxShadow: " 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)",
					// boxShadow: "0px 15px 55px 1px #E6E6E6",
					borderRadius: "12px",
					cursor: "pointer",
					m: 1,
				}}
				onClick={() =>
					navigate(RouteConstants.FEED_DETAILS, {
						state: { feed: props.data },
					})
				}
			>
				<CardContent sx={{ p: 2 }}>
					<Tooltip title={props.data.title}>
						<Typography
							gutterBottom
							fontWeight={600}
							fontSize={16}
							component="div"
							px={2}
							py={2}
							textAlign={"center"}
							height={"60px"}
							// noWrap
						>
							{props.data.title}
						</Typography>
					</Tooltip>
					{/* <Typography
						px={2}
						fontWeight={400}
						fontSize={14}
						color="text.secondary"
					>
						{props.data.post ? props.data.post : props.data.description}
					</Typography> */}
					{flag === "yes" ? (
						<CardMedia
							component="img"
							height="200px"
							width="200px"
							image={url}
							alt="feed image"
							sx={{ objectFit: "contain" }}
						/>
					) : (
						flag === "no" && (
							<Typography
								gutterBottom
								fontWeight={500}
								fontSize={16}
								component="div"
								px={2}
								py={2}
								textAlign={"center"}
								height={"60px"}
								// noWrap
							>
								<div
									dangerouslySetInnerHTML={createMarkup()}
									className="editor"
								></div>
							</Typography>
						)
					)}
				</CardContent>
				<CardActions>
					{/* <Button size="small">Share</Button>
					<Button size="small">Learn More</Button>
					 */}
				</CardActions>
			</Card>
		</Box>
	);
};
