import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import RouteConstants from "../../constants/RouteConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
// import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage";
// import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ImageUploading from "react-images-uploading";
export const AdminFeed = () => {
	const stateFeed = useLocation().state && useLocation().state.feed;
	console.log("stateFeed", stateFeed);

	const state = useSiteState();
	const navigate = useNavigate();
	const [title, setTitle] = React.useState("");
	const [id, setId] = React.useState(0);
	const [contentData, setContentData] = React.useState("");

	const [slug, setSlug] = React.useState("");
	const [post, setPost] = React.useState("");
	const [image, setImage] = React.useState();
	const [description, setDescription] = React.useState("");
	const [order, setOrder] = React.useState(1);
	const [loading, setLoading] = React.useState(false);
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});

	const getUserDetails = () => {
		const data = { user_id: state.user.id.get() };

		ApiHelper.post(APIURLConstants.USER_DETAILS, data)
			.then((res) => {
				setLoading(false);
				// setFirstName(res.data.result.user.firstName);
				// setLastName(res.data.result.user.lastName);
				// setPhone(res.data.result.user.phone);
				// setEmail(res.data.result.user.email);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const data1 = new FormData(event.currentTarget);

		// console.log(data1.get("file"));
		setImage(data1.get("file"));

		console.log("contentData", contentData);
		if (contentData === "") {
			setSnackBarProps({
				openSnack: true,
				variant: "error",
				message: "please enter Post Data",
			});
			return;
		}
		const data = {
			id: id,
			title: title,
			slug: title,
			post: title,
			description: contentData,
			image: data1.get("file"),
			order: order,
			status: 1,
		};
		setLoading(true);
		ApiHelper.post(APIURLConstants.POST_FEED, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					if (id > 0) {
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "Feed details are Updated",
						});
					} else {
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "Feed details are Saved",
						});
					}

					navigate(RouteConstants.FEED_LIST);
					// getUserDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something is wrong",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	useEffect(() => {
		if (stateFeed) {
			setId(stateFeed.id);
			setTitle(stateFeed.title);
			setContentData(stateFeed.description);
			setSlug(stateFeed.slug);
			setPost(stateFeed.post);
			setDescription(stateFeed.description);
			setImage(stateFeed.image);
			setOrder(stateFeed.order);
		} else {
			resetData();
		}
	}, [stateFeed]);

	const resetData = () => {
		setId(0);
		setTitle("");
		setSlug("");
		setPost("");
		setDescription("");
		setImage("");
		setOrder(1);
		setContentData("");
	};
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						Add Feed
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",
							width: "70%",

							mx: 2,
						}}
						component="form"
						noValidate
						encType="multipart/form-data"
						onSubmit={handleSubmit}
					>
						<Box sx={{ padding: 1 }}>
							<TextField
								autoComplete="given-name"
								name="title"
								required
								fullWidth
								id="title"
								label="title"
								// autoComplete="email"
								autoFocus
								color="secondary"
								value={title}
								onChange={(event) => setTitle(event.target.value)}
							/>
						</Box>
						<Box sx={{ padding: 1 }}>
							{/* <input
								type="file"
								className="w-full px-4 py-2"
								label="File"
								name="file"
								onChange={(e) => setImage(e.target.files[0])}
							/> */}
						</Box>
						<Box>
							<CKEditor
								editor={ClassicEditor}
								data={description ? description : ""}
								onReady={(editor) => {
									editor.editing.view.change((writer) => {
										writer.setStyle(
											"height",
											"350px",
											editor.editing.view.document.getRoot()
										);
									});
									// You can store the "editor" and use when it is needed.

									console.log("Editor is ready to use!", editor);
								}}
								onChange={(event, editor) => {
									const data = editor.getData();

									setContentData(data);
								}}
								onBlur={(event, editor) => {
									console.log("Blur.", editor);
								}}
								onFocus={(event, editor) => {
									console.log("Focus.", editor);
								}}
								config={{
									ckfinder: {
										uploadUrl: APIURLConstants.IMAGE_UPLOAD,
									},
								}}
							/>
						</Box>

						<Box sx={{ padding: 1 }}>
							{/* <TextField
								autoComplete="given-name"
								value={slug}
								onChange={(event) => setSlug(event.target.value)}
								name="slug"
								required
								fullWidth
								id="slug"
								label="Slug"
								autoFocus
								color="secondary"
								// error={firstNameErr.length > 0 ? true : false}
								// helperText={firstNameErr.length > 0 ? firstNameErr : ""}
							/> */}
						</Box>
						<Box sx={{ padding: 1 }}>
							{/* <TextField
								value={post}
								onChange={(event) => setPost(event.target.value)}
								autoComplete="given-name"
								name="post"
								required
								fullWidth
								id="post"
								label="Post"
								autoFocus
								color="secondary"
								// error={lastNameErr.length > 0 ? true : false}
								// helperText={lastNameErr.length > 0 ? firstNameErr : ""}
							/> */}
						</Box>
						<Box sx={{ padding: 1 }}>
							{/* <TextField
								value={image}
								onChange={(event) => setImage(event.target.value)}
								autoComplete="given-name"
								name="image"
								required
								fullWidth
								id="image"
								label="Image Url"
								autoFocus
								color="secondary"
								// error={lastNameErr.length > 0 ? true : false}
								// helperText={lastNameErr.length > 0 ? firstNameErr : ""}
							/> */}
						</Box>
						<Box sx={{ padding: 1 }}>
							{/* <TextField
								value={description}
								onChange={(event) => setDescription(event.target.value)}
								fullWidth
								id="description"
								name="description"
								autoComplete="description"
								color="secondary"
								label="description"
								// error={mobileErr.length > 0 ? true : false}
								// helperText={mobileErr.length > 0 ? mobileErr : ""}
							/> */}
						</Box>
						<Box sx={{ padding: 1 }}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
								}}
								color="inherit"
							>
								Submit
							</Button>
						</Box>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
