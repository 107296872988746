import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AddCard } from "./AddCard";
import CardContainer from "./CardContainer";
export const StripeCardList = () => {
	const state = useSiteState();
	const [data, setData] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const postData = {
		user_id: state.user.get() !== "" ? state.user.id.get() : 0,
	};

	const getAllCards = () => {
		ApiHelper.post(APIURLConstants.ALL_CARD, postData)
			.then((res) => {
				console.log("result", res.data.result.allCards.data.length);
				if (res.data.result.allCards.data.length > 0) {
					setData(res.data.result.allCards.data);
				}
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);

				console.log(error);
			});
	};
	const deleteCard = (e, id) => {
		e.preventDefault();
		e.stopPropagation();

		setLoading(true);
		const data = {
			user_id: state.user.id.get(),
			id: id,
		};

		ApiHelper.post(APIURLConstants.DELETE_CARD, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Deleted SuccessFully",
					});
					// getSubscriptionDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something wrong",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	const callBackAddCard = () => {
		handleClose();
		getAllCards();
	};

	useEffect(() => {
		getAllCards();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
						marginLeft: { xs: 0, md: 0 },
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						Cards
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
						<Button
							variant="contained"
							onClick={handleOpen}
							sx={{
								backgroundColor: "tertiary.main",
								color: "primary.main",
								right: 3,
								m: 1,
								display: "flex",
								flexDirection: "row-reverse",
							}}
						>
							Add Card
						</Button>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box
								sx={{
									position: "relative",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",

									bgcolor: "background.paper",
									border: "2px solid #000",
									boxShadow: 24,
									p: { xs: 1, md: 4 },
									width: "50%",
								}}
							>
								<CardContainer callBackAddCard={callBackAddCard} />
							</Box>
						</Modal>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",

							mx: 2,
						}}
					>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 500 }}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell align="right">ID</TableCell>
										<TableCell align="right">Brand</TableCell>
										<TableCell align="right">Card Number</TableCell>
										<TableCell align="right">Expiry</TableCell>
										<TableCell align="right">Status</TableCell>
										<TableCell align="right">Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.length > 0 ? (
										data.map((row, index) => {
											return (
												<TableRow
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
													key={index + 1}
												>
													<TableCell component="th" scope="row">
														{index}
													</TableCell>
													<TableCell align="right">{row.card.brand}</TableCell>
													<TableCell align="right">
														{"**** **** **** " + row.card.last4}
													</TableCell>
													<TableCell align="right">
														{row.card.exp_month + "/" + row.card.exp_year}
													</TableCell>

													<TableCell align="right">Active</TableCell>
													<TableCell align="right">
														{/* <Button
															variant="contained"
															onClick={(e) => {
																deleteCard(e, row.id);
															}}
														>
															Delete
														</Button> */}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
												fontWeight: 400,
												fontSize: 13,
												mx: "auto",
												paddingLeft: 8,
											}}
										>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell component="th" scope="row">
												<Typography
													color="initial"
													alignContent={"center"}
													marginLeft={4}
												>
													No Cards Found
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
