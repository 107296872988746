import { Box, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PriceCard } from "../../components/PriceCard/PriceCard";
import CartCard from "../../components/CartCard/CartCard";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import Snackbars from "../../components/Snackbar/Snackbar";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
import { useCartState, setCartState } from "../../hookState/CartState";
import Helper from "../../utillity/helper";
import { Downgraded } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
export const Cart = () => {
	const [cartItems, setCartItems] = useState([]);
	const state = useSiteState();
	const cartState = useCartState();
	let navigate = useNavigate();
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getCart = () => {
		let data = { user_id: state.user.id.get() };
		ApiHelper.post(APIURLConstants.GET_CART, data)
			.then((res) => {
				setCartItems(res.data.result.products);
				setCartState(res.data.result.products);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeProduct = (product_asin) => {
		if (state.user.get() !== "") {
			let data = { user_id: state.user.id.get(), asin: product_asin };
			ApiHelper.post(APIURLConstants.REMOVE_CART_ITEM, data)
				.then((res) => {
					console.log(res);
					if (res.data.success) {
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "Product removed",
						});
					}
					getCart();
				})
				.catch((error) => {
					console.log(error);
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something wrong",
					});
				});
		} else {
			removeItem(product_asin);
		}
	};
	const removeItem = (itemID) => {
		//create cartCopy
		const cart = Helper.getStorage("cart");
		let cartCopy = [...cart];
		cartCopy = cartCopy.filter((item) => item.asin !== itemID);
		//update State
		setCartItems(cartCopy);
		setCartState(cartCopy);
		Helper.setStorage("cart", cartCopy);
	};
	useEffect(() => {
		if (state.user.get()) {
			getCart();
		} else {
			if (Helper.getStorage("cart")) {
				if (cartState.products.get().length > 0) {
					setCartItems(cartState.products.attach(Downgraded).get());
				} else {
					let cartArray = Helper.getStorage("cart");
					setCartItems(cartArray);
				}
			}
		}
	}, []);

	const [redirectPayment, setRedirectPayment] = React.useState(false);
	const handlePayment = (price, totalReport, type, cartItem) => {
		if (type === "unlimited") {
			navigate(RouteConstants.PAYMENT_GATEWAY, {
				state: { amount: price, type: type, cart: [] },
			});
			return;
		}
		setRedirectPayment(false);
		if (totalReport === 0) {
			navigate(RouteConstants.LANDING);
		} else {
			navigate(RouteConstants.PAYMENT_GATEWAY, {
				state: {
					amount: price * totalReport,
					cart: cartItem,
					type: type,
				},
			});
		}
	};

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				flexWrap: { xs: "nowrap", md: "wrap" },
				justifyContent: "center",
				alignContent: "center",
				p: { xs: 1, md: 4 },
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					overflowY: "scroll",
					height: {
						xs: 300,
						md: "calc(100vh - 156px)",
					},
					paddingTop: "16px",
				}}
			>
				{cartItems.length > 0 ? (
					cartItems.map((cartItem, index) => {
						return (
							<CartCard
								key={index}
								cartItem={cartItem}
								removeProduct={removeProduct}
								showDelete={true}
							/>
						);
					})
				) : (
					<Typography
						fontSize={16}
						color="initial"
						width={{ xs: "100%" }}
						paddingTop={4}
						// marginLeft={{ xs: 5, md: 0 }}
						sx={{ display: "flex" }}
					>
						<RemoveShoppingCartOutlinedIcon /> Cart is Empty
					</Typography>
				)}
			</Box>

			<Box
				sx={{
					my: { xs: 5, md: 0 },
					display: { xs: "flex" },
					justifyContent: { xs: "center" },
				}}
			>
				<Box
					sx={{
						display: { xs: "none", md: "flex" },
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<Box
						sx={{
							marginLeft: 0,
							width: "85%",
							// color: "primary.main",
							bgcolor: "#0081FF",
							height: "90px",
							borderRadius: 2,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								m: "auto",
								display: "flex",
							}}
						>
							<Button
								variant="contained"
								sx={{
									display: "flex",
									justifyContent: "center",
									alignContent: "center",
									alignItems: "center",
								}}
								onClick={() =>
									handlePayment(25, cartItems.length, "single", cartItems)
								}
							>
								Continue to Checkout
							</Button>
						</Box>
					</Box>

					<PriceCard
						colorParams={"#FFAC1C"}
						reportTitle="Premium "
						type="unlimited"
						totalReport={0}
						reportPrice={1}
						buttonTilte={"Subscribe Now"}
						showButton={true}
					/>
				</Box>
				<Box
					sx={{
						display: {
							xs: "flex",
							flexDirection: "column",
							justifyContent: "center",
							md: "none",
						},
					}}
				>
					<Button
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							color: "primary.main",
							bgcolor: "#0081FF",
						}}
						onClick={() =>
							handlePayment(25, cartItems.length, "single", cartItems)
						}
					>
						Continue to Checkout
					</Button>
					<PriceCard
						colorParams={"#FFAC1C"}
						reportTitle="Premium "
						type="unlimited"
						totalReport={0}
						reportPrice={1}
						buttonTilte={"Subscribe Now"}
						showButton={true}
					/>
				</Box>
			</Box>

			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
