import React, { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";

import Typography from "@mui/material/Typography";
// import ApiHelper from "../../utillity/ApiHelper";
// import APIURLConstants from "../../config/config";
import FacebookIcon from "../../assets/fb.svg";
import InstagramIcon from "../../assets/insta.svg";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { FeedCard } from "../../components/FeedCard/FeedCard";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
// import { Feeds } from "../../constants/Constant";
// import { useState } from "@hookstate/core";
// import FacebookIcon from "@mui/icons-material/Facebook";
export const Feed = () => {
	const [feeds, setFeeds] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	useEffect(() => {
		getFeedDetails();
	}, []);

	const getFeedDetails = () => {
		ApiHelper.get(APIURLConstants.ALL_FEEDS)
			.then((res) => {
				setLoading(false);
				setFeeds(res.data.result.feed);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						width: "100%",
						// height: "100%",
						display: "flex",
						flexWrap: "wrap",
						flexDirection: { xs: "column", md: "row" },
						height: { xs: "100%", md: "calc(100vh - 140px)" },
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							p: { xs: 0, md: 1 },
							// justifyContent: "center",
							flexDirection: { xs: "column", md: "column" },
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								paddingTop: 4,
								paddingLeft: { xs: 0, md: 2 },
								borderRadius: "3px",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: { xs: "space-between", md: "start" },
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										mx: 4,
									}}
								>
									<RssFeedIcon
										fontSize="large"
										sx={{ color: "tertiary.main", mx: 1 }}
									/>
									<Typography fontSize={24} fontWeight={500} align="left">
										Feed
									</Typography>
								</Box>

								<Box
									sx={{
										display: { md: "none", xs: "flex" },
										flexDirection: "row-reverse",
										marginLeft: 10,
									}}
								>
									{/* <FacebookIcon fontSize="large" sx={{ color: "#0281ff" }} /> */}

									<Box
										component="img"
										sx={{
											height: 35,
											// marginTop: 1,
											cursor: "pointer",
											marginLeft: "10px",
											marginRight: "10px",
										}}
										alt="logo"
										src={InstagramIcon}
										// onClick={() => loginButton("instagram")}
									/>
									<Box
										component="img"
										sx={{
											height: 35,
											// marginTop: 1,
											cursor: "pointer",
											marginLeft: "10px",
											marginRight: "10px",
										}}
										alt="logo"
										src={FacebookIcon}

										// onClick={() => loginButton("facebook")}
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: { md: "flex", xs: "none" },
									flexDirection: "row",
								}}
							>
								<Typography
									fontSize={24}
									textAlign={"center"}
									fontWeight={500}
									sx={{ mx: "auto" }}
								>
									Stay connected to the latest market trends
								</Typography>
							</Box>

							<Box sx={{ mx: 8, display: { md: "flex", xs: "none" } }}>
								{/* <FacebookIcon fontSize="large" sx={{ color: "#0281ff" }} /> */}
								<Box
									component="img"
									sx={{
										height: 50,
										marginTop: 1,
										cursor: "pointer",
										marginLeft: "10px",
										marginRight: "10px",
									}}
									alt="logo"
									src={FacebookIcon}

									// onClick={() => loginButton("facebook")}
								/>
								<Box
									component="img"
									sx={{
										height: 50,
										marginTop: 1,
										cursor: "pointer",
										marginLeft: "10px",
										marginRight: "10px",
									}}
									alt="logo"
									src={InstagramIcon}
									// onClick={() => loginButton("instagram")}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: { md: "none", xs: "flex" },
								flexDirection: "row",
							}}
						>
							<Typography
								fontSize={"1em"}
								textAlign={"center"}
								fontWeight={500}
								sx={{ mx: "auto" }}
							>
								Stay connected to the latest market trends!
							</Typography>
						</Box>

						{/* <Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							<Typography fontSize={26} textAlign={"center"} fontWeight={500}>
								Stay connected to the latest market trends
							</Typography>
							<FacebookIcon
								fontSize="large"
								sx={{ color: "#0281ff", mx: 1, my: 0.5 }}
							/>
						</Box> */}

						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								flexDirection: { xs: "column", md: "row" },
								justifyContent: { xs: "center", md: "flex-start" },
								mx: { xs: "auto", md: 6 },
							}}
						>
							{feeds.length > 0 &&
								feeds.map((product, index) => {
									return (
										<FeedCard key={index} data={product} myAccount={true} />
									);
								})}
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
