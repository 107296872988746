import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Feed from "../../assets/feed.png";
import Parser from "html-react-parser";

export const FeedDetails = () => {
	const state = useLocation().state.feed;

	const createMarkup = () => {
		return { __html: state.description };
	};
	return (
		<>
			<Box sx={{ p: 2 }}>
				<Card
					sx={{
						width: "80%",
						mx: "auto",
						// height: 467,
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
					}}
				>
					<CardContent>
						<Typography
							gutterBottom
							fontWeight={700}
							fontSize={40}
							component="div"
							px={2}
							py={2}
							lineHeight={"50px"}
						>
							{state.title}
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								justifyItems: "center",
								alignItems: "center",
							}}
						></Box>

						{/* <Typography
							px={2}
							py={2}
							fontWeight={400}
							fontSize={20}
							color="text.secondary"
						>
							{state.post}
						</Typography> */}

						<Typography
							px={2}
							py={2}
							fontWeight={400}
							fontSize={20}
							color="text.secondary"
						>
							{
								<div
									dangerouslySetInnerHTML={createMarkup()}
									className="editor"
								></div>
							}
							{/* {<div>{Parser(state.description)}</div>} */}

							{/* {state.description} */}
						</Typography>
					</CardContent>
					<CardActions>
						<Button size="small">Share</Button>
						<Button size="small">Learn More</Button>
					</CardActions>
				</Card>
			</Box>
		</>
	);
};
