import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box } from "@mui/material";
import { CardForm } from "./CardForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(
	"pk_test_51Kj4nIFphs2VTbD5HXjDaL2Ijumm9VxcqrgEDUWkV4P5U3hu1eAzCzczKEmY9dfAZIPp4Jkrhl0Gn2BMsj2bRcp900Iu2XOjXM"
);

export default function CardContainer(props) {
	return (
		<Elements stripe={stripePromise} hidePostalCode={true}>
			<CardForm callBackAddCard={props.callBackAddCard} />
		</Elements>
	);
}
