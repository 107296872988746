import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import {
	Box,
	TextField,
	Button,
	Typography,
	Chip,
	Modal,
	Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export const AdminFaq = () => {
	const useStyles = makeStyles((theme) => ({
		margin: {
			margin: theme.spacing(1),
		},
		socialButton: {
			margin: theme.spacing(1),
		},

		textField: {
			width: 400,
			[theme.breakpoints.down("md")]: {
				width: 320,
			},
			display: "flex",
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&:hover fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&.Mui-focused fieldset": {
					borderColor: theme.palette.secondary.main,
				},
			},
			"&:hover .MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
			"& .Mui-focused.MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
		},
		outlinedInput: {
			"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
			"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: "200px",
					height: "70px",
				},
			},
		},
	}));
	const classes = useStyles();
	const state = useSiteState();
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [open, setOpen] = React.useState(false);
	const [question, setOuestion] = React.useState("");
	const [answer, setAnswer] = React.useState("");
	const [queErr, setQueErr] = React.useState("");
	const [ansErr, setAnsErr] = React.useState("");
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const postData = {
		user_id: state.user.get() !== "" ? state.user.id.get() : 0,
	};

	const getAllFAQ = () => {
		ApiHelper.get(APIURLConstants.GET_FAQ)
			.then((res) => {
				console.log(res.data.result.faq);

				console.log(res.data);
				setData(res.data.result.faq);

				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);

				console.log(error);
			});
	};
	const deleteCard = (e, id) => {
		e.preventDefault();
		e.stopPropagation();

		setLoading(true);
		const data = {
			user_id: state.user.id.get(),
			id: id,
		};

		ApiHelper.post(APIURLConstants.GET_FAQ, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Deleted SuccessFully",
					});
					// getSubscriptionDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "Something wrong",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	useEffect(() => {
		getAllFAQ();
	}, []);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		if (data.get("question") === "") {
			setQueErr("Question is required");
			return;
		}

		if (data.get("answer") === "") {
			setAnsErr("Answer is required");
			return;
		}
		let obj = {};
		ApiHelper.post(APIURLConstants.POST_FAQ, data)
			.then((res) => {
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "FAQ Added SuccessFully",
					});
					getAllFAQ();
					handleClose();
				} else {
					// if (res.data.password) {
					// 	setansErr(res.data.message);
					// 	return;
					// } else {
					// 	setqueErr(res.data.message);
					// 	return;
					// }
				}

				// loading.set(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const removeFeed = (id) => {
		const data = {
			id: id,
		};
		ApiHelper.post(APIURLConstants.DELETE_FAQ, data)
			.then((res) => {
				setLoading(false);
				getAllFAQ();
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						FAQ
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
						<Button
							variant="contained"
							onClick={handleOpen}
							sx={{
								backgroundColor: "tertiary.main",
								color: "primary.main",
								right: 3,
								m: 1,
								display: "flex",
								flexDirection: "row-reverse",
							}}
						>
							Add FAQ
						</Button>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",

									bgcolor: "background.paper",
									border: "2px solid #000",
									boxShadow: 24,
									p: { xs: 1, md: 4 },
								}}
							>
								<Box
									component="form"
									noValidate
									onSubmit={handleSubmit}
									sx={{ mt: 3, width: { xs: 290, md: 400 } }}
								>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												required
												fullWidth
												id="question"
												label="Question"
												name="question"
												autoComplete="question"
												color="secondary"
												error={queErr.length > 0 ? true : false}
												helperText={queErr.length > 0 ? queErr : ""}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												required
												fullWidth
												name="answer"
												label="Answer"
												id="answer"
												autoComplete="new-answer"
												color="secondary"
												error={ansErr.length > 0 ? true : false}
												helperText={ansErr.length > 0 ? ansErr : ""}
											/>
										</Grid>
									</Grid>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										sx={{
											mt: 3,
											mb: 2,
											color: "primary.main",
											bgcolor: "tertiary.main",
										}}
										color="inherit"
									>
										Submit
									</Button>
								</Box>
							</Box>
						</Modal>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",

							mx: 2,
						}}
					>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 500 }}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell align="right">ID</TableCell>
										<TableCell align="right">Question</TableCell>
										<TableCell align="right">Answer</TableCell>
										{/* <TableCell align="right">Action</TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.length > 0 ? (
										data.map((row, index) => {
											return (
												<TableRow
													key={index}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
													key={index + 1}
												>
													<TableCell component="th" scope="row">
														{index}
													</TableCell>
													<TableCell align="right">{row.question}</TableCell>
													<TableCell align="right">{row.answer}</TableCell>

													<TableCell align="right">
														<IconButton
															aria-label="delete"
															onClick={() => {
																removeFeed(row.id);
															}}
														>
															<DeleteIcon />
														</IconButton>
													</TableCell>
													{/* <TableCell align="right">
														<Button
															variant="contained"
															onClick={(e) => {
																deleteCard(e, row.id);
															}}
														>
															Delete
														</Button>
													</TableCell> */}
												</TableRow>
											);
										})
									) : (
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
												fontWeight: 400,
												fontSize: 13,
												mx: "auto",
												paddingLeft: 8,
											}}
										>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell component="th" scope="row">
												<Typography
													color="initial"
													alignContent={"center"}
													marginLeft={4}
												>
													No FAQ Found
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
