import * as React from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { categories } from "../../constants/Constant";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			// Default transform is "translate(14px, 20px) scale(1)""
			// This lines up the label with the initial cursor position in the input
			// after changing its padding-left.
			transform: "translate(34px, 20px) scale(1);",
		},
	},
	inputRoot: {
		color: theme.palette.secondary.main,
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			paddingLeft: 26,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: theme.palette.secondary.main,
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: theme.palette.tertiary.main,
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: theme.palette.secondary.main,
		},
	},
}));
export default function Category(props) {
	const classes = useStyles();
	const [value, setValue] = React.useState(categories[0]);
	// console.log("input value", inputValue);
	// console.log("input value", value);

	return (
		<Autocomplete
			classes={classes}
			disableClearable
			id="category-states-demo"
			sx={{ width: { xs: 300, md: 300 } }}
			value={value}
			size="small"
			onChange={(event, newValue) => {
				setValue(newValue);
				props.handleCategory(newValue.id);
			}}
			options={categories}
			getOptionLabel={(option) => option.name}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Categories"
					sx={{ color: "black" }}
					inputProps={{
						...params.inputProps,
					}}
				/>
			)}
		/>
	);
}
