import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import ProductCard from "../../components/Product/ProductCard";
import Snackbars from "../../components/Snackbar/Snackbar";
import { setCartState } from "../../hookState/CartState";
import Category from "../../components/Category/Category";
export const Budget = () => {
	const [perPage] = useState(9);
	const [currentPage] = useState(1);
	const [products, setProducts] = useState([]);
	const [search] = useState("");
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	// const [filter, setFilter] = React.useState("");

	// const handleFilter = (event) => {
	// 	setFilter(event.target.value);
	// };
	//CArt Offline

	let [cart, setCart] = useState([]);
	let localCart = Helper.getStorage("cart");
	const addItem = (item) => {
		console.log("trace 1");
		let cartCopy = [...cart];

		let { id } = item;
		console.log("asin", cartCopy);

		let existingItem = cartCopy.find((cartItem) => cartItem.id == id);
		console.log("existingItem", existingItem);

		if (existingItem) {
			console.log("asin", cartCopy.length);

			setSnackBarProps({
				openSnack: true,
				variant: "success",
				message: "Product Already in Cart",
			});
			return;
		} else {
			cartCopy.push(item);
		}
		setCart(cartCopy);
		setCartState(cartCopy);
		Helper.setStorage("cart", cartCopy);
		setSnackBarProps({
			openSnack: true,
			variant: "success",
			message: "Product Added in Card",
		});

		// let stringCart = JSON.stringify(cartCopy);
		// console.log("cartCopy", cartCopy);
		// console.log("cart", cart);
	};

	const getProducts = () => {
		const data = {
			per_page: perPage,
			current_page: currentPage,
			search: search,
		};
		console.log(data);
		ApiHelper.post(APIURLConstants.get_products, data)
			.then((res) => {
				// console.log(res);
				console.log("res.data.result.products", res.data.result.products);
				setProducts(res.data.result.products);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		getProducts();
	}, [perPage, currentPage]);

	// useEffect(() => {
	// 	console.log(search);
	// 	console.log(search.length);

	// 	getProducts();

	// 	//getProducts();
	// }, [search]);
	return (
		<>
			<Box
				sx={{
					width: "100%",
					// height: "100%",
					display: "flex",
					flexWrap: "wrap",
					flexDirection: { xs: "column", md: "row" },

					// mx: 4,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						p: 1,
						// justifyContent: "center",
						flexDirection: { xs: "column", md: "column" },
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
							justifyContent: "space-between",
							alignContent: "space-between",
							paddingTop: 2,
							paddingLeft: "32px",
							borderRadius: "3px",
							ms: 4,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignContent: "center",
								alignItems: "center",
							}}
						>
							<Typography fontSize={20} fontWeight={500} marginRight={1}>
								Budget Products
							</Typography>

							<Typography
								fontSize={14}
								fontWeight={400}
								sx={{ backgroundColor: "#EBF2FF", height: 25 }}
							>
								Click on a box below to view top Budget product{" "}
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignContent: "flex-end",
							}}
						>
							<Category />
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: { xs: "column", md: "row" },
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{products.map((product, index) => {
							return (
								<ProductCard
									key={index}
									data={product}
									myAccount={false}
									addItem={addItem}
								/>
							);
						})}
					</Box>
				</Box>
				{snackBarProps.openSnack && (
					<Snackbars
						{...snackBarProps}
						onClose={() =>
							setSnackBarProps({ ...snackBarProps, openSnack: false })
						}
					></Snackbars>
				)}
			</Box>
		</>
	);
};
