import { createState, useState } from "@hookstate/core";
import Helper from "../utillity/helper";
const initialState = {
	name: "",
};

const socialLoginState = createState(initialState);

export const setSocialLoginState = (props) => {
	if (props) {
		socialLoginState.set({
			...initialState,
			name: props,
		});
	}
};
export const useSocialLoginState = () => {
	return useState(socialLoginState);
};
export const changeSocialState = () => {
	Helper.removeStorage("loginOption");
	socialLoginState.set(initialState);
};
