import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
export const AllUser = () => {
	const state = useSiteState();
	const [data, setData] = React.useState([]);

	const [loading, setLoading] = React.useState(true);

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getSubscriptionDetails = () => {
		ApiHelper.get(APIURLConstants.ALL_USER)
			.then((res) => {
				setLoading(false);
				// console.log("======>", res);
				const key = "email";
				const arrayUniqueByKey = [
					...new Map(
						res.data.result.user.map((item) => [item[key], item])
					).values(),
				];

				// console.log(arrayUniqueByKey);

				setData(arrayUniqueByKey);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleSubmit = () => {
		setLoading(true);
		const data = {
			user_id: state.user.id.get(),
		};

		ApiHelper.post(APIURLConstants.UPDATE_USER, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Something is wrong",
					});
					getSubscriptionDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "User details are updated",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	useEffect(() => {
		getSubscriptionDetails();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						All User
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",

							mx: 2,
						}}
					>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 650 }}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell align="right">ID</TableCell>
										<TableCell align="right">First Name</TableCell>
										<TableCell align="right">Last Name</TableCell>
										<TableCell align="right">Email</TableCell>
										<TableCell align="right">Phone</TableCell>
										<TableCell align="right">Subscription</TableCell>

										<TableCell align="right">Status</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.length > 0 ? (
										data.map((row) => {
											return (
												<TableRow
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<TableCell component="th" scope="row">
														{row.id}
													</TableCell>
													<TableCell align="right">{row.firstName}</TableCell>
													<TableCell align="right">{row.lastName}</TableCell>
													<TableCell align="right">{row.email}</TableCell>
													<TableCell align="right">{row.phone}</TableCell>
													<TableCell align="right">
														{row.plan_id === 1 ? "Unlimited" : ""}
													</TableCell>
													<TableCell align="right">
														{row.is_active === 1 ? "Active" : "InActive"}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
												fontWeight: 400,
												fontSize: 13,
												mx: "auto",
												paddingLeft: 4,
											}}
										>
											<Typography
												color="initial"
												alignContent={"center"}
												marginLeft={4}
											>
												No Subscription purchased yet
											</Typography>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
