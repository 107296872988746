import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
// import PaymentForm from "./PaymentForm";
import { CheckoutForm } from "./CheckoutForm";
import { useLocation } from "react-router-dom";
// const PUBLIC_KEY ="pk_test_51Kj4nIFphs2VTbD5HXjDaL2Ijumm9VxcqrgEDUWkV4P5U3hu1eAzCzczKEmY9dfAZIPp4Jkrhl0Gn2BMsj2bRcp900Iu2XOjXM";
const PUBLIC_KEY =
	"pk_live_51Kj4nIFphs2VTbD5dYAKjUK30ROQcWoiVFqOVr3Lmat4hVxtQXzSjxg3scFgxZrTzyfxHNHiFDsMyILvUexVlCRu00lnlvhDhC";
const stripeTestPromise = loadStripe(PUBLIC_KEY);
// const options = {
// 	// passing the client secret obtained from the server
// 	clientSecret:
// 		"sk_test_51Kj4nIFphs2VTbD5lbHPWL9PdBAXdIG0arjZMLqgN80EbR6Dpi3e3prrafjKOAm7OQ5nCac1gc2mEToyBENSdiZC00ejkTuiwv",
// };
// const options = {
// 	// passing the client secret obtained from the server
// 	clientSecret:
// 		"sk_test_51Kj4nIFphs2VTbD5lbHPWL9PdBAXdIG0arjZMLqgN80EbR6Dpi3e3prrafjKOAm7OQ5nCac1gc2mEToyBENSdiZC00ejkTuiwv",
// };
export default function StripeContainer() {
	const state = useLocation().state;

	return (
		<Elements stripe={stripeTestPromise}>
			<CheckoutForm amount={state.amount} cart={state.cart} type={state.type} />
		</Elements>
	);
}
