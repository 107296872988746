import { createState, useState } from "@hookstate/core";
// import Helper from "../utillity/helper";
const initialState = {
	visible: false,
	admin: false,
};

const profileState = createState(initialState);

export const setProfileState = (props) => {
	profileState.set({
		...initialState,
		visible: props,
		admin: props,
		// products: props,
	});
};

export const useProfileState = () => {
	return useState(profileState);
};
