import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RouteConstants from "../constants/RouteConstant";
import Home from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { SignUp } from "../pages/SignUp/SignUp";
import { Product } from "../pages/Product/Product";
import Landing from "../pages/Landing/Landing";
import { Feed } from "../pages/Feed/Feed";
import { MyAccount } from "../pages/MyAccount/MyAccount";
import { Price } from "../pages/Price/Price";
import { Cart } from "../pages/Cart/Cart";
import StripeContainer from "../components/Stripe/StripeContainer";
import { Privacy } from "../pages/Privacy/Privacy";
import { ContactUs } from "../pages/ContactUs/ContactUs";
import { ProductInfo } from "../components/ProductInfo/ProductInfo";
import { TopSelling } from "../pages/Product/TopSelling";
import { Budget } from "../pages/Product/Budget";
import { Profile } from "../pages/Profile/Profile";
import { Subscription } from "../pages/Subscription/Subscription";
import { AllUser } from "../pages/AllUser/AllUser";
import { Payments } from "../pages/Payments/Payments";
import { FeedDetails } from "../pages/Feed/FeedDetails";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { FeedList } from "../pages/Feed/FeedList";
import { AdminFeed } from "../pages/Feed/AdminFeed";
import { CardList } from "../pages/Card/CardList";
import { AddCard } from "../pages/CreditCard/AddCard";
import { StripeCardList } from "../pages/CreditCard/StripeCardList";
import { Faq } from "../pages/FAQ/Faq";
import { AdminFaq } from "../pages/FAQ/AdminFaq";
import { ChangePwd } from "../pages/Profile/ChangePwd";
import { Reset } from "../pages/ResetPwd/Reset";
import { ResetPwd } from "../pages/ResetPwd/ResetPwd";
export const InnerRouter = () => {
	return (
		<>
			<Routes>
				<Route exact path={RouteConstants.HOME} element={<Home />} />
				<Route exact path={RouteConstants.CALLBACK} element={<Landing />} />
				<Route exact path={RouteConstants.LOGIN} element={<Login />} />
				<Route exact path={RouteConstants.SIGNUP} element={<SignUp />} />
				<Route exact path={RouteConstants.product} element={<Product />} />
				<Route exact path={RouteConstants.LANDING} element={<Landing />} />
				<Route exact path={RouteConstants.FEED} element={<Feed />} />
				<Route exact path={RouteConstants.MYACCOUNT} element={<MyAccount />} />
				<Route exact path={RouteConstants.PRICE} element={<Price />} />
				<Route exact path={RouteConstants.CART} element={<Cart />} />
				<Route
					exact
					path={RouteConstants.PAYMENT_GATEWAY}
					element={<StripeContainer />}
				/>
				<Route exact path={RouteConstants.PRIVACY} element={<Privacy />} />
				<Route exact path={RouteConstants.CONTACT} element={<ContactUs />} />
				<Route
					exact
					path={RouteConstants.PRODUCTINFO}
					element={<ProductInfo />}
				/>
				<Route
					exact
					path={RouteConstants.TOPSELLING}
					element={<TopSelling />}
				/>

				<Route exact path={RouteConstants.BUDGET} element={<Budget />} />
				<Route exact path={RouteConstants.PROFILE} element={<Profile />} />
				<Route
					exact
					path={RouteConstants.SUBSCRIPTION}
					element={<Subscription />}
				/>
				<Route exact path={RouteConstants.ALL_USER} element={<AllUser />} />
				<Route exact path={RouteConstants.ALL_PAYMENT} element={<Payments />} />
				<Route
					exact
					path={RouteConstants.FEED_DETAILS}
					element={<FeedDetails />}
				/>
				<Route exact path={RouteConstants.DASHBOARD} element={<Dashboard />} />

				<Route exact path={RouteConstants.FEED_LIST} element={<FeedList />} />
				<Route exact path={RouteConstants.ADMIN_FEED} element={<AdminFeed />} />

				<Route
					exact
					path={RouteConstants.CARD_LIST}
					element={<StripeCardList />}
				/>
				<Route exact path={RouteConstants.ADD_CARD} element={<AddCard />} />
				<Route exact path={RouteConstants.FAQ} element={<Faq />} />
				<Route exact path={RouteConstants.ADMIN_FAQ} element={<AdminFaq />} />
				<Route exact path={RouteConstants.CHANGE_PWD} element={<ChangePwd />} />
				<Route exact path={RouteConstants.FORGOT_PWD} element={<Reset />} />
				<Route exact path={RouteConstants.RESET_PWD} element={<ResetPwd />} />

				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	);
};
