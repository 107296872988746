import axios from "axios";
import RouteConstants from "../constants/RouteConstant";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const Axios = axios.create({
	// withCredentials: true,
	baseURL: `${new URL(process.env.PUBLIC_URL, window.location.origin)}`,
});

//let notificationTimer: unknown = null;
/**
 * Here Modify request data if needed like Login token, company ids
 */
Axios.interceptors.request.use((request) => {
	// const auth_token = '';
	// request.headers['Authorization'] = `Bearer ${auth_token}`;

	return request;
});

const redirectLogin = (statusCode) => {
	//... Unregister the service worker.
	if (statusCode === 401 || statusCode === 403) {
		const date = new Date();
		const ts = date.getTime();

		//... Append a time stamp so that the browsers don't cache the url.
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.origin);
		const url = `${publicUrl}?ts=${ts}`;

		//... Wait for sometime to get the service worker unregistered before redirecting to the root url.
		setTimeout(() => {
			sessionStorage.clear();
			//... Redirect to home so that user gets the ADFS sign-in page.
			window.location.href = url;
		}, 3000);
	}
	return;
};

/**
 * Intercept Responses and check if response status os OK.
 */
Axios.interceptors.response.use(
	(response) => {
		/**
		 * Added temperory solution to check expired data.
		 */
		redirectLogin(response.status);
		return response;
	},
	(error) => {
		// console.log(error.response.status);
		//clearTimeout(notificationTimer);

		if (error.response && error.response.status === 401) {
			console.log(error.response.status);
			window.location.href = RouteConstants.SESSION_TIMEOUT;
		} else {
			return Promise.reject(error);
		}
	}
);
export default Axios;
